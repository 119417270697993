/* eslint-disable react/prop-types */
import Pagination from "@mui/material/Pagination";
import { Row } from "assets/jss/common-styled";
import React from "react";
import styled from "styled-components";

export default function PageNumberButtons(props) {
  const { length, onChangePage } = props;
  const onChange = (e, page) => {
    onChangePage(page);
  };
  if (length <= 1) return null;
  return (
    <Container>
      <Pagination count={length} size="small" onChange={onChange} />
    </Container>
  );
}

const Container = styled.div`
  ${Row};
  justify-content: center;
  width: 100%;
  margin-top: 0.75rem;
`;
