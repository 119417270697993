import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Column, Row } from "assets/jss/common-styled";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Admin() {
  const { history } = React.useContext(Context);
  const admin = { id: "admin", pw: "admin!1234" };
  const [input, setInput] = React.useState({ id: "", pw: "" });

  function handler() {
    if (admin.id === input.id && admin.pw === input.pw) {
      localStorage.setItem("admin", "true");
      history.push("/");
    } else {
      alert("아이디와 비밀번호를 확인해주세요.");
    }
  }
  function handlerId(event) {
    setInput({ ...input, id: event.target.value });
  }
  function handlerPw(event) {
    setInput({ ...input, pw: event.target.value });
  }
  return (
    <Body>
      <div>
        <TextField
          label="ID"
          variant="outlined"
          fullWidth
          onChange={handlerId}
        />
        <TextField
          label="PW"
          variant="outlined"
          type="password"
          fullWidth
          onChange={handlerPw}
        />
        <Button onClick={handler} variant="contained">
          Login
        </Button>
      </div>
    </Body>
  );
}

const Body = styled.div`
  ${Row};
  justify-content: center;
  width: 100%;
  height: 100vh;
  > div {
    ${Column}
    justify-content: space-between;
    width: 400px;
    height: 200px;
  }
`;
