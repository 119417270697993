export const dateFormat = (d) => {
  if (d.length === 8)
    return (
      d.substring(0, 4) + "." + d.substring(4, 6) + "." + d.substring(6, 8)
    );
  return d;
};

export const getYear = () => {
  return new Date().getFullYear();
};

export const getMonth = () => {
  return new Date().getMonth() + 1;
};
