import profile from "assets/images/profile.png";
import signature from "assets/images/signature.png";
import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Greeting(props) {
  const { nls, getLangType } = React.useContext(Context);
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("company")}
        path2={nls("ceo")}
        category1="company"
      />
      <Main>
        {getLangType() === "ko" ? (
          <div className="container">
            <div className="main-content">
              <img className="profile-img" src={profile} alt="profile" />
              <div className="profile-content">
                <div className="title">
                  <div>신뢰할 수 있는</div>
                  <div>금융파트너가 되겠습니다.</div>
                </div>
                <div className="text">
                  <p className="space">
                    안녕하십니까. 마르스자산운용 대표 이예호입니다.
                  </p>
                  <p>
                    2021년 4월 자산운용업 등록을 통해 출범한 마르스자산운용은
                    “고객이 신뢰할 수 있는 금융파트너”를
                  </p>
                  <p className="space">
                    목표로 각 분야 최고 전문가들이 주축이 되어 설립하였습니다.
                  </p>
                  <p>
                    풍부한 부동산 개발 및 투자 경험을 보유한 전문가들의 체계적인
                    운용과 리스크 관리를 기반으로,
                  </p>
                  <p className="space">
                    그 어떤 시장의 변화에도 고객의 자산을 안정적으로 지켜
                    나가겠습니다.
                  </p>
                  <p>
                    마르스자산운용이 앞으로 이루어 나갈 성과는 모두 투자자
                    여러분들의 믿음과 지지를 통해서만
                  </p>
                  <p className="space">가능함을 알고 있습니다.</p>
                  <p>
                    투자자 여러분들의 신뢰에 보답하는 올바른 투자를 하는 회사,
                  </p>
                  <p className="space">
                    투자자를 위해 생각하고 행동하는 회사로서 투자자 한 분 한
                    분께 최선을 다할 것을 약속 드립니다.
                  </p>
                  <p>감사합니다.</p>
                </div>
              </div>
            </div>
            <div className="sub-content">
              <span>마르스자산운용(주) 대표 이예호</span>
              <img src={signature} alt="signature" />
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="main-content">
              <img className="profile-img" src={profile} alt="profile" />
              <div className="profile-content">
                <div className="title">
                  <div>We will be your reliable financial partners.</div>
                </div>
                <div className="text">
                  <p className="space">
                    MARS Asset Management Inc., which was launched through
                    registration as an asset management business in April 2021,
                    was founded by top experts in each field with the aim of
                    becoming a “financial partner that customers can trust”.
                  </p>

                  <p className="space">
                    Based on the systematic management and risk management of
                    experts with abundant real estate development and investment
                    experience, we will safely protect the assets of our
                    customers in any market circumstance.
                  </p>
                  <p className="space">
                    We know that the future achievements of MARS Asset
                    Management can only be achieved through the trust and
                    support of investors.
                  </p>
                  <p className="space">
                    We promise to do our best to each and every investor as a
                    company that makes the right investment in return for the
                    trust of investors, and as a company that thinks and acts
                    for investors.
                  </p>
                  <p className="space">MARS Asset Management Inc.</p>
                </div>
              </div>
            </div>
            <div className="sub-content">
              <span>{nls("sign")}</span>
              <img src={signature} alt="signature" />
            </div>
          </div>
        )}
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  min-height: 44.375rem;
  padding: 6.25rem 0;
  width: 100%;
  background-color: white;
  position: relative;
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    .main-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      ${breakPoints.lessThan("md")`
        justify-content: space-around;
      `};
      .profile-img {
        width: 20.5625rem;
      }
      .profile-content {
        display: flex;
        flex-direction: column;
        width: 51.75rem;
        ${breakPoints.lessThan("md")`
          margin: 2rem 0;
          padding: 2rem;
        `};
        .title {
          border-bottom: 1px solid rgb(72, 72, 72);
          height: 6rem;
          > div {
            font-size: 1.875rem;
            line-height: 2.5rem;
            color: black;
            font-weight: bold;
          }
        }
        .text {
          padding-top: 1.875rem;
          > p {
            font-size: 1rem;
            line-height: 1.375rem;
            margin: 0;
            padding: 0;
          }
          .space {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
    .sub-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      max-height: 4.125rem;
      ${breakPoints.lessThan("md")`
        flex-direction: column;
        margin: 2rem 0;
        padding: 2rem;
      `};
      > span {
        font-size: 1.25rem;
        font-weight: bold;
      }
      > img {
        width: 12.875rem;
      }
    }
  }
`;
