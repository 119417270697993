import { breakPoints, Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import MediaContainer from "components/Media/MediaContainer";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Media(props) {
  const { nls } = React.useContext(Context);
  const { ...rest } = props;
  const level2 = [
    { name: nls("disclosure"), value: "/notice" },
    { name: nls("press"), value: "/media" },
  ];
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = firestore
      .collection("media")
      .orderBy("id", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setItems((prev) => [
            ...prev.filter((item) => item.id !== data.id),
            data,
          ]);
        });
      });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1={nls("notice")} path2={nls("press")} level2={level2} />
      <Main>
        <div className="container">
          <MediaContainer items={items} />
        </div>
      </Main>
      <Footer />
    </div>
  );
}
const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
  }
`;
