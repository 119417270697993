import { Slide } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import imgLogo from "assets/images/logo2--white.png";
import iconBlog from "assets/imgs/icon--blog.png";
import iconFacebook from "assets/imgs/icon--facebook.png";
import iconInstagram from "assets/imgs/icon--instagram.png";
import { breakPoints, Column, Row } from "assets/jss/common-styled";
import React from "react";
import styled from "styled-components";
import { getLangType, nls } from "utils/lang";
import PrivacyPolicy from "./PrivacyPolicy";

const Footer = () => {
  const [checked, setChecked] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const handler = (link) => {
    setChecked(false);
    window.open(link, "_blank");
  };

  const handleClose = () => {
    setModal(false);
  };
  const handleOpen = () => {
    setModal(true);
  };

  const goBlog = () => {
    window.open("https://blog.naver.com/yeho207", "_blank");
  };

  return (
    <Container>
      <Top>
        <div className="top-content">
          <div className="top-list">
            <img src={imgLogo} alt="logo" />
            <div className="spliter" />
            <div className="text-box">
              <MainText>{`${nls("name")} ${nls("addr")}`}</MainText>
              <MainText>
                {nls("tel")}
                <div className="mini-spliter" />
                {nls("fax")}
                <div className="mini-spliter" />
                e-mail: contact@marsamc.co.kr
              </MainText>
              <SubText>
                Copyright by MARS Asset Management All Rights Reserved.
              </SubText>
            </div>
          </div>
          <div className="button-list">
            <img src={iconBlog} alt="btn1" onClick={goBlog} />
            <img src={iconInstagram} alt="btn3" />
            <img src={iconFacebook} alt="btn2" />
          </div>
        </div>
      </Top>
      <MobileTop>
        <MobileText>{`${nls("name")} ${nls("addr")}`}</MobileText>
        <MobileText>
          <div className="mini-spliter" />
          {nls("tel")}
        </MobileText>
        <MobileText>
          <div className="mini-spliter" />
          {nls("fax")}
        </MobileText>
        <MobileText>
          <div className="mini-spliter" />
          e-mail: contact@marsamc.co.kr
        </MobileText>
      </MobileTop>
      <Bottom>
        <img src={imgLogo} alt="logo" />
        <div>
          {getLangType() === "ko" && (
            <StyledButton2 onClick={handleOpen}>개인정보취급방침</StyledButton2>
          )}
          {getLangType() === "ko" && (
            <StyledButton onClick={() => setChecked((prev) => !prev)}>
              연관사이트
            </StyledButton>
          )}
          <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
            <StyledPaper elevation={4}>
              <div
                onClick={() => handler("https://www.fsc.go.kr")}
                className="item"
              >
                금융위원회
              </div>
              <div className="spliter" />
              <div
                onClick={() => handler("http://www.fss.or.kr/fss/kr/main.html")}
                className="item"
              >
                금융감독원
              </div>
              <div className="spliter" />
              <div
                onClick={() => handler("https://www.kofia.or.kr/index.do")}
                className="item"
              >
                금융투자협회
              </div>
              <div className="spliter" />
              <div
                onClick={() => handler("http://dart.fss.or.kr")}
                className="item"
              >
                전자공시시스템
              </div>
              <div className="spliter" />
              <div
                onClick={() => handler("http://fine.fss.or.kr/main/index.jsp")}
                className="item"
              >
                금융소비자 정보포털
              </div>
            </StyledPaper>
          </Slide>
        </div>
      </Bottom>
      <StyledModal
        open={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <ModalPaper>
            <PrivacyPolicy handler={handleClose} />
          </ModalPaper>
        </Fade>
      </StyledModal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 13.75rem;
  background-color: rgb(72, 72, 72);
  img {
    cursor: pointer;
    ${breakPoints.lessThan("md")`
      display: none;
    `};
  }
  ${breakPoints.lessThan("md")`
    width: 100%;
  `};
`;
const StyledModal = styled(Modal)`
  ${Row};
  justify-content: center;
`;
const ModalPaper = styled.div`
  ${Column};
  justify-content: center;
  width: 40rem;
  height: 80vh;
`;
const StyledPaper = styled(Paper)`
  ${Column};
  position: absolute;
  width: 15rem;
  bottom: 2.5rem;
  margin: 0;
  padding: 0.5rem 0;
  > .item {
    ${Row};
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    color: rgb(80, 80, 80);
    padding: 0 0 0 1rem;
    cursor: pointer;
    &:hover {
      background-color: rgb(230, 230, 230);
    }
  }
  .spliter {
    width: 95%;
    height: 0;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
`;
const StyledButton = styled.div`
  width: 7rem;
  height: 1.625rem;
  border: 1px solid white;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  ${Row};
  justify-content: center;
  ${breakPoints.lessThan("md")`
    display: none;
  `};
`;
const StyledButton2 = styled.div`
  width: 7rem;
  height: 1.625rem;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  ${Row};
  justify-content: center;
  margin-right: 2rem;
  ${breakPoints.lessThan("md")`
    font-size: 0.65rem;
    margin-right: 1.5rem;
  `};
`;
const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 11.25rem;
  background-color: rgb(72, 72, 72);
  ${breakPoints.lessThan("md")`
    display: none;
  `};
  .top-content {
    display: flex;
    justify-content: space-between;
    width: 75rem;
    height: 5rem;
    .top-list {
      display: flex;
      align-items: center;
      height: 100%;
      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
    .button-list {
      display: flex;
      justify-content: space-between;
      width: 10rem;
      height: 100%;
      > img {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
      }
    }
  }
  .spliter {
    width: 0;
    height: 5rem;
    border-left: 1px solid white;
    margin-left: 2rem;
    margin-right: 1rem;
  }
`;
const Bottom = styled.div`
  ${Row};
  width: 100%;
  background-color: rgb(49, 49, 49);
  height: 2.5rem;
  justify-content: center;
  ${breakPoints.lessThan("md")`
      justify-content: space-between;
      height: 6rem;
  `};
  img {
    display: none;
    width: 7rem;
    margin-left: 1.5rem;
    ${breakPoints.lessThan("md")`
      display: block;
  `};
  }
  > div {
    ${Row};
    width: 75rem;
    height: 100%;
    justify-content: flex-end;
    position: relative;
  }
`;
const MainText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  color: white;
  .text {
    margin-right: 1rem;
  }
  .mini-spliter {
    height: 1.5rem;
    border-left: 1px solid white;
    margin: 0 1rem;
  }
`;

const SubText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  color: rgb(175, 175, 175);
  ${breakPoints.lessThan("md")`
    display: none;
  `};
`;
const MobileTop = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 1rem;
  width: 100%;
  height: 100%;
  ${breakPoints.lessThan("md")`
    display: flex;
  `};
`;
const MobileText = styled.span`
  ${Row};
  font-size: 0.7rem;
  color: white;
  .mini-spliter {
    height: 1rem;
    border-left: 1px solid white;
    margin: 0 0.5rem 0 0;
  }
`;
export default Footer;
