import { Column, Row } from "assets/jss/common-styled";
import React from "react";
import styled from "styled-components";

const PrivacyPolicy = (props) => {
  const { handler } = props;
  return (
    <PPContainer>
      <Title>개인정보취급방침</Title>
      <Content>
        <p>
          마르스자산운용㈜(이하 “회사”라 함)는 개인정보보호법 제30조 및
          정보통신망이용촉진및정보보호에 관한법률 제27조의2에 따라 정보주체의
          개인정보보호 및 권익을 보호하고 개인정보와 관련한 정보주체의 고충을
          원활하게 처리할 수 있도록 다음과 같은 처리(취급)방침을 두고 있습니다.
        </p>
        <h4>제1조(개인정보의 처리 목적)</h4>
        <p>
          회사는 개인정보를 다음 각호의 목적을 위해 처리합니다. 처리한
          개인정보는 다음의 목적 외의 용도로는 사용되지 않으며 이용 목적이
          변경될 시에는 관련 법령상 허용되는 경우가 아닌 한, 사전 동의를 구할
          예정입니다.
        </p>
        <h5>1. (금융)거래 관련</h5>
        <p>
          (금융)거래와 관련하여 신용조회회사 또는 신용정보집중기관에 대한
          개인신용정보의 조회, (금융)거래 관계의 설정 여부의 판단, (금융)거래
          관계의 설정·유지·이행·관리, 금융사고 조사, 분쟁 해결, 민원 처리 및
          법령상 의무이행 등의 목적으로 개인정보를 처리합니다.
        </p>
        <h5>2. 상품 및 서비스 홍보 및 판매 권유</h5>
        <p>
          고객 만족도 조사를 통한 신규 서비스 개발 및 맞춤 서비스 제공,
          인구통계학적 특성에 따른 서비스 제공 및 광고의 게재, 서비스의 유효성
          확인, 경품 지급, 사은행사 등 고객의 편의 및 참여기회 제공, 접속 빈도
          파악, 회원의 서비스 이용에 대한 통계 등의 목적으로 개인정보를
          처리합니다.
        </p>
        <h5>3. 직원채용·인사관리 및 금융기관 임직원 정보교류 등</h5>
        <p>
          회사 직원채용·인사관리, 전직 금융인 재취업지원 및 금융기관 간 임직원
          정보교류 등을 목적으로 개인정보를 처리합니다.
        </p>
        <h4>제2조(개인정보의 처리 및 보유 기간)</h4>
        <p>
          ① (금융)거래와 관련한 개인(신용)정보는 수집·이용에 관한 동의일로부터
          (금융)거래 종료일까지 위 이용목적을 위하여 보유·이용됩니다. 단,
          (금융)거래 종료일 이후에는 금융사고 조사, 분쟁 해결, 민원처리, 법령상
          의무이행 및 회사의 위험관리 업무만을 위하여 보유·이용됩니다.
        </p>
        <p>
          ② 개인(신용)정보의 조회를 목적으로 수집된 개인(신용)정보는 수집·이용에
          대한 동의일로부터 고객에 대한 신용정보 제공·조회 동의의 효력 기간까지
          보유·이용됩니다. 단, 신용정보 제공·조회 동의의 효력 기간 종료 후에는
          금융사고 조사, 분쟁 해결, 민원처리 및 법령상 의무이행만을 위하여
          보유·이용됩니다.
        </p>
        <p>
          ③ 상품 및 서비스 홍보 및 판매 권유 등과 관련한 개인(신용)정보는
          수집·이용에 관한 동의일로부터 동의 철회일까지 보유·이용됩니다. 단,
          동의 철회일 후에는 제1조의 목적과 관련된 사고 조사, 분쟁 해결,
          민원처리, 법령상 의무이행만을 위하여 보유·이용됩니다.
        </p>
        <p>
          ④ 회사 임직원 등의 개인정보는 퇴직 후에도 사고 예방·조사, 분쟁 해결,
          민원처리, 법령상 의무이행 만을 위하여 보유·이용됩니다.
        </p>
        <h4>제3조(개인정보 수집 출처 등 고지)</h4>
        <p>
          ① 회사는 정보 주체 이외로부터 수집한 개인정보를 처리하는 때에는 정당한
          사유가 없는 한 정보 주체의 요구가 있은 날로부터 3일 이내에 수집 출처,
          처리 목적, 개인정보 처리의 정지를 요구할 권리가 있다는 사실을 정보
          주체에게 알립니다.
        </p>
        <p>
          ② 개인정보 보호법 제20조제2항 각호에 근거하여 제1항에 따른 정보 주체의
          요구를 거부하는 경우에는 정당한 사유가 없는 한 정보 주체의 요구가 있은
          날로부터 3일 이내에 그 거부의 근거와 사유를 정보 주체에게 알립니다.
        </p>
        <h4>제4조(개인정보의 제3자 제공)</h4>
        <p>
          ① 회사는 원칙적으로 고객의 개인정보를 제1조에서 명시한 목적 범위
          내에서 처리하며, 고객의 사전 동의 없이는 본래의 범위를 초과하여
          처리하거나 제3자에게 제공하지 않습니다. 단, 다음의 각호의 경우에는
          고객 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는
          개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수
          있습니다.
        </p>
        <p>1. 고객이 사전에 제3자 제공 및 공개에 동의한 경우 </p>
        <p>2. 다른 법률에 특별한 규정이 있는 경우 </p>
        <p>
          3. 고객 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 고객 또는
          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는
          경우
        </p>
        <p>
          4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
          알아볼 수 없는 형태로 개인정보를 제공하는 경우
        </p>
        <p>
          ② 회사는 필요한 경우 다음 각호와 같이 개인정보를 제공할 수 있습니다.
        </p>
        <p>
          1. 제공받는 자 : 현재 회사는 아래의 개인정보 제공에 대하여 해당사항이
          없습니다.
        </p>
        <p>- 신용정보집중기관 및 신용조회회사에 대한 제공</p>
        <p>- 제휴업체</p>
        <p>2. 제공받는 자의 이용 목적</p>
        <p>- 신용정보집중기관 및 신용조회회사에 대한 제공</p>
        <p>
          ▸ 본인의 신용을 판단하기 위한 자료로 활용하거나 공공기관에서
          정책자료로 활용
        </p>
        <p>- 제휴업체에 대한 제공</p>
        <p>▸ 제휴 상품·서비스의 홍보 및 판매 권유</p>
        <p>3. 개인정보 제공항목</p>
        <p>- 신용정보집중기관 및 신용조회회사에 대한 제공</p>
        <p>▸ 개인식별정보, 신용거래정보, 신용능력정보, 신용평가를 위한 정보</p>
        <p>- 제휴업체에 대한 제공</p>
        <p>
          ▸ 개인식별정보, (금융)거래 정보, 거래신청서에 기재된 개인식별 정보
          외의 정보 또는 고객이 제공한 정보(주거 및 가족사항, 거주기간,
          세대구성, 결혼여부 등)
        </p>
        <p>※ 본 동의 이전에 수집된 개인(신용)정보도 포함됩니다. </p>
        <p>4. 개인정보 보유 기간</p>
        <p>
          - 개인(신용)정보는 제공된 날로부터 동의 철회 시 또는 제공된 목적을
          달성할 때까지 보유·이용됩니다. 동의 철회 또는 제공된 목적 달성 후에는
          위에 기재된 이용목적과 관련된 금융사고 조사, 분쟁해결, 민원처리,
          법령상 의무이행을 위하여 필요한 범위 내에서만 보유·이용됩니다.
        </p>
        <p>
          ③ 회사는 고객의 동의를 받거나 개인정보 보호법 제15조 제1항 제2호,
          제3호 및 제5호에 따라 개인정보를 제공하는 경우에는 개인정보 보호법
          제17조 제2항 각호의 사항과 개인정보의 열람, 정정·삭제, 처리정지 등의
          요구를 할 수 있다는 사실을 고지하지 아니합니다.
        </p>
        <p>
          ④ 위탁계약 시 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자
          제공 금지 및 책임부담 등을 명확히 규정하고 있습니다. 업체 변경 시
          개인정보처리(취급)방침의 변경을 통해 고지하겠습니다.
        </p>
        <h4>제5조(개인정보처리 위탁) </h4>
        <p>
          ① 회사는 고객의 동의를 받아 다음 각호와 같이 개인정보의 처리를
          위탁하게 됩니다.
        </p>
        <p>
          1. 수탁업체: 현재 회사는 아래의 개인정보 제공에 대하여 해당사항이
          없습니다.
        </p>
        <p>- (금융)거래의 설정·유지·이행·관리 등을 위한 마케팅 업체</p>
        <p>- 상품 및 서비스 홍보 및 판매 권유를 위한 마케팅 업체</p>
        <p>- 사은행사 관련 이벤트 업체</p>
        <p>- 고객 만족도 조사를 위한 리서치 업체</p>
        <p>2. 수탁의 목적</p>
        <p>
          - (금융)거래의 설정·유지·이행·관리 등에 필요한 경우로서 위탁받은
          업무의 수행
        </p>
        <p>
          - 상품 및 서비스 홍보 및 판매 권유, 사은행사, 고객 만족도 조사 등에
          필요한 경우로서 위탁받은 업무의 수행
        </p>
        <p>3. 개인정보 제공항목</p>
        <p>
          - 개인식별정보: 성명, 주민등록번호 등 고유식별정보, 국적, 직업,
          주소·전자우편주소·전화번호 등 연락처
        </p>
        <p>
          - (금융)거래정보: 상품종류, 거래조건(이자율, 만기, 담보 등), 거래일시,
          금액 등 거래 설정 및 내역 정보
        </p>
        <p>
          - 개인식별정보 외에 거래신청서에 기재된 정보 또는 고객이 제공한 정보
        </p>
        <p>: 주거 및 가족사항, 거주기간, 세대구성, 결혼여부 등</p>
        <p>※ 본 동의 이전에 수집된 개인(신용)정보도 포함됩니다. </p>
        <p>4. 개인정보 보유 기간</p>
        <p>
          - 개인(신용)정보는 제공된 날로부터 동의 철회 시 또는 제공된 목적을
          달성할 때까지 보유·이용됩니다. 동의 철회 또는 제공된 목적 달성 후에는
          위에 기재된 이용목적과 관련된 사고 조사, 분쟁해결, 민원처리, 법령상
          의무이행을 위하여 필요한 범위 내에서만 보유·이용됩니다.
        </p>
        <p>
          ② 위탁계약 시 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자
          제공 금지 및 책임부담 등을 명확히 규정하고, 업체 변경 시
          개인정보처리(취급)방침의 변경을 통해 고지하겠습니다.
        </p>
        <h4>제6조(고객의 권리·의무 및 그 행사방법) </h4>
        <p>
          ① 고객은 회사가 처리하는 자신 및 14세 미만 아동(법정대리인만 해당)의
          개인정보의 열람을 요구할 수 있습니다.
        </p>
        <p>
          ② 자신의 개인정보를 열람한 고객은 사실과 다르거나 확인할 수 없는
          개인정보에 대하여 회사에 정정 또는 삭제를 요구할 수 있습니다. 다만,
          다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그
          삭제를 요구할 수 없습니다.
        </p>
        <p>
          ③ 고객은 회사에 대하여 자신의 개인정보 처리의 정지를 요구할 수
          있습니다. 다만 다음 각호의 어느 하나에 해당하는 경우에는 회사는 해당
          사유를 고객에게 알리고, 처리정지 요구를 거절할 수 있습니다.
        </p>
        <p>
          1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
          경우
        </p>
        <p>
          2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그
          밖의 이익을 부당하게 침해할 우려가 있는 경우
        </p>
        <p>
          3. 개인정보를 처리하지 아니하면 고객과 약정한 서비스를 제공하지 못하는
          등 계약의 이행이 곤란한 경우로서 고객이 그 계약의 해지 의사를 명확하게
          밝히지 아니한 경우
        </p>
        <h4>제7조(처리하는 개인정보의 항목) </h4>
        <p>
          회사는 (금융)거래의 설정·유지·이행·관리 및 상품서비스의 제공에 필요한
          최소한의 필수정보 및 선택정보를 다음 각호와 같이 수집하고 있습니다.
        </p>
        <p>1. 필수적 정보</p>
        <p>
          - 개인식별정보: 성명, 주민등록번호 등 고유식별정보, 국적, 직업,
          주소·전자우편주소·전화번호 등 연락처
        </p>
        <p>
          - (금융)거래정보: 상품종류, 거래조건(이자율, 만기, 담보 등), 거래일시,
          금액 등 거래 설정 및 내역 정보
        </p>
        <p>- 신용평가를 위한 정보(여신거래에 한함)</p>
        <p>▸ 신용능력정보: 재산·채무·소득의 총액, 납세실적</p>
        <p>
          ▸ 신용도판단정보: 연체, 대위변제, 대지급, 부도, 관련인 발생사실 등
        </p>
        <p>
          - 기타 금융거래의 설정·유지·이행·관리를 위한 상담, 채권관리 등을 통해
          생성되는 정보
        </p>
        <p>2. 선택적 정보</p>
        <p>
          - 개인식별정보 외에 거래신청서에 기재된 정보 또는 고객이 제공한 정보
        </p>
        <p>: 주거 및 가족사항, 거주기간, 세대구성, 결혼여부 등</p>
        <p>
          ※회사는 고객의 사생활을 침해할 우려가 있는 민감정보에 대해서는
          원칙적으로 수집하지 않으며, 필요한 경우 고객의 별도 동의를 받아
          수집하고 동의 목적을 위해서만 제한적으로 이용합니다. 다만, 민감정보의
          정확성, 최신성을 주기적으로 확인합니다.
        </p>
        <p>3. 수집방법</p>
        <p>- 회사에 내방한 고객으로부터 직접 수집</p>
        <p>- 홈페이지, 서면 양식, 팩스, 전화, 상담 게시판, 이메일 등</p>
        <p>- 생성정보 수집 툴을 통한 수집</p>
        <h4>제8조(개인정보의 파기)</h4>
        <p>
          ① 회사는 개인정보의 보유기간이 경과된 경우에는 다음 각호의 사유가 없는
          한 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당
          서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
          다음 각호의 사유가 없는 한 개인정보의 처리가 불필요한 것으로 인정되는
          날로부터 5일 이내에 그 개인정보를 파기합니다.
        </p>
        <p>
          1. 신용정보집중기관 또는 신용조회회사가 신용정보의 집중관리·활용 또는
          개인의 신용도 등을 평가하기 위한 목적으로 개인신용정보를 보유하는
          경우(보유기간에 한함)
        </p>
        <p>
          2. 신용정보회사 등이 민·형사상의 책임 또는 시효가 지속되거나 분쟁의
          입증자료로서 개인 신용정보를 보유하는 경우
        </p>
        <p>3. 상법 제33조 등 법령에 따라 보존하여야 하는 경우</p>
        <p>4. 기타 이와 유사한 정당한 사유가 있는 경우</p>
        <p>
          ② 개인정보가 기록된 출력물, 서면 등은 파쇄 또는 소각의 방법으로
          파기하고, 전자적 파일 형태의 개인정보는 영구 삭제하는 방법으로
          파기합니다.
        </p>

        <h4>제9조(개인정보의 안전성 확보 조치) </h4>
        <p>
          회사는 개인정보보호법제29조에 따라 다음 각호와 같이 안전성 확보에
          필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.
        </p>
        <p>1. 개인정보의 관리(암호화)</p>
        <p>
          - 고객의 개인정보는 파일 잠금 기능을 사용하는 등의 별도의 보안기능을
          사용하여 보호하고 있습니다.
        </p>
        <p>2. 해킹 등에 대비한 기술적 대책</p>
        <p>
          - 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
          막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며
          외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로
          감시 및 차단하고 있습니다.
        </p>
        <p>3. 개인정보처리시스템 접근 제한</p>
        <p>
          - 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경,
          말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고
          있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
          있습니다.
        </p>
        <p>4. 개인정보 취급 직원의 최소화 및 교육</p>
        <p>
          - 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여
          개인정보를 관리하는 대책을 시행하고 있습니다.
        </p>
        <h4>제10조(개인정보 처리방침의 변경) </h4>
        <p>
          회사가 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기,
          변경된 내용을 지속적으로 공개하며, 변경된 내용은 고객이 쉽게 확인할 수
          있도록 변경 전·후를 비교하여 공개합니다.
        </p>

        <h4>제11조(임직원의 개인정보 처리) </h4>
        <p>
          회사 임직원의 개인정보 처리에 관하여도 본 개인정보 처리방침의 규정이
          준용됩니다.
        </p>

        <h4>제12조 (권익침해 구제방법) </h4>
        <p>
          고객은 개인정보침해로 인한 신고나 상담이 필요하신 경우 아래 기관에
          문의하시기 바랍니다.
        </p>
        <p>1. 개인정보분쟁조정위원회</p>
        <p>
          2. 한국인터넷진흥원 개인정보침해신고센터(www.kopico.or.kr/02-1336)
        </p>
        <p>3. 정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)</p>
        <p>4. 대검찰청 첨단범죄수사과 (www.spo.go.kr/02-3480-2000)</p>
        <p>5. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</p>

        <h4>제13조(개인정보 보호책임자 등) </h4>
        <p>
          ①개인정보보호법제31조제1항및정보통신망이용촉진및정보보호에관한법률제27조제1항에
          따른 회사의 개인정보 보호 및 관리책임자는 다음과 같습니다.
        </p>
        <p>[개인정보보호 및 관리책임자]</p>
        <p>직책 준법감시인</p>
        <p>성명 윤태윤 </p>
        <p>전화번호 (02) 6191-0044</p>
        <p>
          ② 회사의 개인정보 열람청구를 접수·처리하는 부서 및 담당자는 다음과
          같습니다.
        </p>
        <p>담당부서/직책/성명 경영관리팀 대리 정미진</p>
        <p>담당자 전화번호 (02)6191-0041</p>
        <p>
          ③ 회사는 개인정보 보호책임자, 개인정보 관리책임자 및 개인정보 열람청구
          담당자를 지정하거나 변경하는 경우, 지정 및 변경 사실, 성명과 부서의
          명칭 및 전화번호 등 연락처를 개인정보처리(취급)방침의 변경을 통해
          고지합니다.
        </p>

        <p>이 개인정보 처리지침은 2021. 4. 14 부터 적용됩니다.</p>
      </Content>
      <Bottom>
        <div onClick={handler}>닫기</div>
      </Bottom>
    </PPContainer>
  );
};
const PPContainer = styled.div`
  ${Column};
  width: 40rem;
  height: 80vh;
  border-radius: 0.2rem;
  background-color: white;
`;
const Title = styled.div`
  ${Row};
  width: 100%;
  height: 4rem;
  justify-content: flex-start;
  padding: 0 2rem;
  font-weight: 600;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  overflow-y: scroll;
  padding: 0 2rem;
  > p {
    font-size: 0.9rem;
  }
  > h4 {
    font-size: 1rem;
    font-weight: 600;
  }
  > h5 {
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
const Bottom = styled.div`
  ${Row};
  width: 100%;
  justify-content: flex-end;
  padding: 0 2rem;
  height: 4rem;
  cursor: pointer;
  font-size: 0.9rem;
  > div {
    ${Column};
    justify-content: center;
    width: 5rem;
    height: 2.5rem;
    border-radius: 0.2rem;
    font-weight: 600;
    &:hover {
      background-color: rgb(240, 240, 240);
    }
  }
`;
export default PrivacyPolicy;
