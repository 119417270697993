import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { breakPoints, Column } from "assets/jss/common-styled";
import { firestore, storage } from "components/Firebase/Firebase";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { dateFormat } from "utils/funcs";

function NoticeUpload(props) {
  const { history } = React.useContext(Context);
  const { ...rest } = props;
  const [items, setItems] = React.useState([]);
  const [id, setId] = React.useState(-1);
  const [input, setInput] = React.useState({});
  const [type, setType] = React.useState("management");
  const [file, setFile] = React.useState(null);

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };
  const upload = () => {
    const temp = {
      ...input,
      id,
      type,
      date: dateFormat(input?.date),
    };
    if (file) {
      temp["file"] = file?.name;
    }
    firestore
      .collection("notice")
      .doc(id.toString())
      .set({ ...temp })
      .then(() => {
        console.log("Document successfully written!");
        history.push("/notice");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    if (file) {
      const storageRef = storage.ref();
      storageRef
        .child(`notice/${file.name}`)
        .put(file)
        .on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  React.useEffect(() => {
    const temp = [];
    firestore
      .collection("notice")
      .orderBy("id", "desc")
      .limit(1)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setItems(temp);
      });
  }, []);
  React.useEffect(() => {
    if (items.length > 0) setId(items[0]?.id + 1);
  }, [items]);

  function handlerTitle(event) {
    setInput({ ...input, title: event.target.value });
  }
  function handlerContent(event) {
    setInput({ ...input, content: event.target.value });
  }
  function handlerDate(event) {
    setInput({ ...input, date: event.target.value });
  }
  function handlerType(event) {
    setType(event.target.value);
  }
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1="공지사항" />
      <Main>
        <div className="container">
          <div className="form-box">
            <TextField
              label="제목"
              variant="outlined"
              fullWidth
              onChange={handlerTitle}
            />
            <TextField
              label="내용"
              variant="outlined"
              fullWidth
              multiline
              onChange={handlerContent}
            />
            <TextField
              label="작성일"
              variant="outlined"
              fullWidth
              placeholder="YYYY.MM.DD"
              onChange={handlerDate}
            />
            <select value={type} onChange={handlerType}>
              <option value="management">경영공시</option>
              <option value="fund">펀드공시</option>
            </select>
            <input type="file" name="file" onChange={handleFile.bind(this)} />
            <Button onClick={upload} variant="contained">
              업로드
            </Button>
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
  }
  .form-box {
    ${Column};
    width: 100%;
    min-height: 40rem;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export default withRouter(NoticeUpload);
