import Button from "@material-ui/core/Button";
import { breakPoints, Column, Row } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import NoticeList from "components/Notice/NoticeList";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Status(props) {
  const { nls, history } = React.useContext(Context);

  const { ...rest } = props;
  const level2 = [
    { name: nls("disclosure"), value: "/notice" },
    { name: nls("press"), value: "/media" },
  ];

  const [items, setItems] = React.useState([]);
  const [isAdmin, setAdmin] = React.useState(false);

  function handler() {
    history.push("/notice_upload");
  }

  React.useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
    const unsubscribe = firestore
      .collection("notice")
      .orderBy("id", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setItems((prev) => [
            ...prev.filter((item) => item.id !== data.id),
            data,
          ]);
        });
      });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("notice")}
        path2={nls("disclosure")}
        level2={level2}
      />
      <Main>
        <div className="container">
          <NoticeList items={items} setItems={setItems} isAdmin={isAdmin} />
        </div>
        {isAdmin && (
          <StatusBottom>
            <Button onClick={handler} variant="outlined">
              작성하기
            </Button>
          </StatusBottom>
        )}
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
  }
`;
const StatusBottom = styled.div`
  ${Row};
  width: 75rem;
  height: 3rem;
  margin-top: 4rem;
  padding-right: 2rem;
  justify-content: flex-end;
`;
