import "assets/scss/mars.scss?v=1.9.0";
import Context from "Context";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { nlsLang } from "utils/lang";
import Admin from "views/Admin/Admin";
import Compliance from "views/Compliance/Compliance";
import Greeting from "views/Greeting/Greeting.js";
import History from "views/History/History";
import HistoryAdmin from "views/History/HistoryAdmin";
import Home from "views/Home/Home";
import HomeAdmin from "views/Home/HomeAdmin";
import Information from "views/Information/Information";
import InformationAdmin from "views/Information/InformationAdmin";
import Map from "views/Map/Map.js";
import Media from "views/Media/Media";
import MediaUpload from "views/Media/MediaUpload";
import Members from "views/Members/Members.js";
import Notice from "views/Notice/Notice";
import NoticeUpload from "views/Notice/NoticeUpload";
import Organization from "views/Organization/Organization";
import Philosophy from "views/Philosophy/Philosophy";
import Risk from "views/Risk/Risk";
import Status from "views/Status/Status";
import StatusUpload from "views/Status/StatusUpload";

var hist = createBrowserHistory();

const App = () => {
  const [lang, setLang] = React.useState("ko");
  function changeLang(l) {
    setLang(l);
  }
  function nls(key) {
    return nlsLang(key, lang);
  }
  function getLangType() {
    return lang;
  }
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Home />
          </Context.Provider>
        </Route>
        <Route path="/home_admin" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <HomeAdmin />
          </Context.Provider>
        </Route>
        <Route path="/information" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Information />
          </Context.Provider>
        </Route>
        <Route path="/information_admin" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <InformationAdmin />
          </Context.Provider>
        </Route>
        <Route path="/history" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <History />
          </Context.Provider>
        </Route>
        <Route path="/history_admin" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <HistoryAdmin />
          </Context.Provider>
        </Route>
        <Route path="/greeting" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Greeting />
          </Context.Provider>
        </Route>
        <Route path="/greeting" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Greeting />
          </Context.Provider>
        </Route>
        <Route path="/members" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Members />
          </Context.Provider>
        </Route>
        <Route path="/organization" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Organization />
          </Context.Provider>
        </Route>
        <Route path="/philosophy" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Philosophy />
          </Context.Provider>
        </Route>
        <Route path="/map" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Map />
          </Context.Provider>
        </Route>
        <Route path="/risk" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Risk />
          </Context.Provider>
        </Route>
        <Route path="/compliance" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Compliance />
          </Context.Provider>
        </Route>
        <Route path="/status">
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Status />
          </Context.Provider>
        </Route>
        <Route path="/notice" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Notice />
          </Context.Provider>
        </Route>
        <Route path="/media" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Media />
          </Context.Provider>
        </Route>
        <Route path="/admin" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <Admin />
          </Context.Provider>
        </Route>
        <Route path="/status_upload" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <StatusUpload />
          </Context.Provider>
        </Route>
        <Route path="/media_upload" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <MediaUpload />
          </Context.Provider>
        </Route>
        <Route path="/notice_upload" exact={true}>
          <Context.Provider
            value={{ lang, changeLang, nls, getLangType, history: hist }}
          >
            <NoticeUpload />
          </Context.Provider>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
