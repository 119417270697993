import { ClickAwayListener, MenuItem, MenuList } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import kor from "assets/imgs/kor.svg";
import usa from "assets/imgs/usa.svg";
import { breakPoints, Row } from "assets/jss/common-styled";
import Button from "components/CustomButtons/Button.js";
import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
const Menu = (props) => {
  const context = React.useContext(Context);
  const { getLangType, changeLang } = context;

  const { color } = props;
  const [selectedLang, setSelectedLang] = React.useState(
    getLangType() === "ko" ? <Kor /> : <Usa />
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(anchorEl || event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLangKor = () => {
    setSelectedLang(<Kor />);
    changeLang("ko");
    handleClose();
  };
  const handleLangUsa = () => {
    setSelectedLang(<Usa />);
    changeLang("en");
    handleClose();
  };
  const useStyles = makeStyles(() =>
    createStyles({
      default: {
        backgroundColor: "transparent",
        color: color,
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: 0,
        marginLeft: "1rem",
        boxShadow: "0px 0px 0px",
        padding: 0,
      },
      paper: {
        height: "100%",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: 0,
        zIndex: 9999,
        padding: 0,
        marginTop: "0.5rem",
      },
      popper: {
        zIndex: 9999,
      },
    })
  );
  const classes = useStyles();

  function Kor() {
    return (
      <StyledItem onClick={handleLangKor}>
        <img src={kor} alt="kor" />
        <span>KOR</span>
      </StyledItem>
    );
  }
  function Usa() {
    return (
      <StyledItem onClick={handleLangUsa}>
        <img src={usa} alt="usa" />
        <span>ENG</span>
      </StyledItem>
    );
  }
  return (
    <Wrap>
      <Button
        className={classes.default}
        onClick={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {selectedLang}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        className={classes.popper}
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="menu-list-grow">
              <MenuItem>
                <Kor />
              </MenuItem>
              <MenuItem>
                <Usa />
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Wrap>
  );
};
const Wrap = styled.div`
  ${Row};
`;
const StyledItem = styled.div`
  ${Row};
  justify-content: space-between;
  width: 5.5rem;
  padding: 0;
  font-weight: 600;
  ${breakPoints.lessThan("md")`
    width: 2.5rem;
  `};
  > span {
    ${breakPoints.lessThan("md")`
      display: none;
    `};
  }
  > img {
    margin: 0 0.5rem 0;
  }
  li {
    padding: 0;
  }
`;
Menu.propTypes = {
  color: PropTypes.oneOf(["white", "black"]).isRequired,
};
export default withRouter(Menu);
