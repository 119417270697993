const lang = {
  ko: {
    name: "마르스자산운용(주)",
    company: "회사소개",
    process: "운용프로세스",
    assets: "운용현황",
    notice: "공지사항",
    ceo: "CEO인사말",
    people: "주요인력",
    organization: "조직도",
    location: "오시는길",
    philosophy: "운용철학",
    risk: "리스크관리",
    compliance: "컴플라이언스",
    disclosure: "공시정보",
    press: "언론보도",
    landing1: "신뢰할 수 있는 금융파트너",
    landing2: "풍부한 부동산 개발 및 투자 경험",
    landing3: "항상 투자자를 먼저 생각하는 회사",
    mars: "마르스 운용프로세스",
    sign: "마르스자산운용(주) 대표 이예호",

    m1: "이예호 대표",
    m2: "이경재 이사",
    m3: "윤태윤 상무(준법감시인)",
    m3_1: "윤태윤 상무",
    m3_2: "(준법감시인)",
    m4: "김종인 상무",
    m5: "장준성 감사",

    education: "학력",
    history: "주요경력",
    certificate: "자격",

    school1: "서울대학교 조선해양공학과 졸업\n서울대학교 경영전문대학원 졸업",
    school2: "서울대학교 조선해양공학과 졸업",
    school3: "서울대학교 농학과 졸업",
    school4:
      "국민대학교 회계학과 졸업\n국민대학교 경영학과 석사 졸업\nNYU(New York University) Intensive MBA",
    school5: "한양대학교 법학과 졸업",

    hist1:
      "부동산 개발 및 컨설팅 전문회사 비스컴퍼니 Founder & CEO (2018-2020)",
    hist2:
      "글로벌 경영 컨설팅회사 L.E.K. Consulting Senior Associate Consultant (2017-2019)",
    hist3:
      "삼성생명 자산운용본부 (1991-2011)\n(기업신용분석 10년 / 신용리스크 등 리스크 관리 6년 / 기업금융, 주식, 채권 아웃소싱 4년)\n에스인베스트먼트 신기술금융 투자 2년 (2017-2019)",
    hist4:
      "우리자산신탁 금융서비스실 (2015-2021)\n삼성자산운용 펀드운용팀 (1990-1996)\n삼성생명 재무심사팀 (1984-2009)\n효성캐피탈 / W저축은행",
    hist5: "제 57회 사법시험 합격\n제 47기 사법연수원 수료\n법무법인 하우",

    certi1: "투자자산운용사, 건축기사, 공인중개사",
    certi2: "투자자산운용사",
    certi3: "CFA, FRM, 투자자산운용사",
    certi4: "증권분석사 ",
    certi5: "변호사",

    addr: "서울특별시 강남구 테헤란로 44길 8, 2층",
    tel: "대표전화: 02-6191-0041",
    fax: "팩스: 02-6205-9436",

    phcotent1_1: "투자자 이익실현 및",
    phcotent1_2: "권리보호를 최우선 가치로 인식",

    phcotent2_1: "부동산 전문성을 통해, 운용 전 단계부터",
    phcotent2_2: "안정성과 수익성 모두 확보 가능한 상품 발굴 및 구조 설계",
    phcotent3_1: "투자대상 선정에서 운용까지 철저한 검토 및 분석에",
    phcotent3_2: "기반한 자산운용",

    phcotent4_1: "시장변화에 따른 위험요인 최소화로",
    phcotent4_2: "운용자산의 안정성과 합리적 수익성 최우선적 확보",

    phtitle1: "투자자 중심의 자산운용",
    phtitle2: "투자 단계부터 경쟁력 있는 자산",
    phtitle3: "철저한 분석에 기반한 체계적 운용",

    phtitle4: "엄격하고 체계적인 리스크관리 / 준법감시",

    statusType1: "Funds",
    statusType2: "Other Projects",

    information: "회사소개",
    companyHistory: "연혁",
  },
  en: {
    name: "MARS Asset Management Inc.",
    company: "Company",
    process: "Process",
    assets: "Assets",
    notice: "Notice",
    ceo: "Our Message",
    people: "Our People",
    organization: "Organization",
    location: "Location",
    philosophy: "Investment Philosophy",
    risk: "Risk Management",
    compliance: "Compliance",
    disclosure: "Disclosure",
    press: "Press",
    landing1: "Reliable financial partner",
    landing2: "Abundant experience in real estate development and investment",
    landing3: "Company that always puts investors first",
    mars: "Investment Process",
    sign: "CEO Yeho Lee",
    education: "Education",
    history: "History",
    certificate: "Certificate",
    m1: "CEO Yeho Lee",
    m2: "Director Gyeongjae Lee",
    m3: "Executive director Taeyoon Yoon (Compliance officer)",
    m3_1: "Executive director Taeyoon Yoon",
    m3_2: "(Compliance officer)",
    m4: "Executive director 김종인",
    m5: "장준성 감사",

    school1:
      "Seoul National University, BS degree in Engineering\nSeoul National University Executive MBA",
    school2: "Seoul National University, BS degree in Engineering",
    school3: "Seoul National University, BS degree in Science",
    school4:
      "Kookmin University, BS degree in Accounting\nKookmin University, Master degree in Business administration\nNYU(New York University) Intensive MBA",
    school5: "Hanyang University, BS degree in Law",

    hist1:
      "Founder & CEO of Bees Company, Real estate development and consulting company (2018-2020)",
    hist2:
      "Senior Associate Consultant of L.E.K. Consulting, Global management consulting firm (2017-2019)",
    hist3:
      "Senior Manager of Asset management group of Samsunglife (1991-2011)\n(Corporate credit analysis 10 years / Credit risk management 6 years / Corporate finance, stocks, bond outsourcing 4 years)\nDirector / Compliance officer of S Investment, Venture capital (2017-2019)",
    hist4:
      "Executive Director of Woori Asset Trust (2015-2021)\nFund Management Team of Samsung Asset Management (1990-1996)\nFinance Team of Samsunglife(1984-2009)\nHyosung Capital / W Savings Bank",
    hist5:
      "The 47th Judicial Reserch and Training\nInstitute, Supreme Court of Korea\nLaw Firm HOW",

    certi1: "Architectural Engineer, Realtor, Investment Manager",
    certi2: "Investment Manager",
    certi3: "CFA, FRM, Investment Manager",
    certi4: "Certified Investment Analyst",
    certi5: "Lawyer",

    addr:
      "2th floor, 8, Teheran-ro 44-gil, Gangnam-gu, Seoul, Republic of Korea",
    tel: "Tel: +82 2-6191-0041",
    fax: "Fax: +82 2-6205-9436",
    phcotent1_1: "Recognize the realization of investors' profits",
    phcotent1_2: "and protection of rights as the top priority",
    phcotent2_1:
      "Deal sourcing and structure design that can secure both stability",
    phcotent2_2:
      "and profitability from the pre-operation stage through real estate expertise",
    phcotent3_1: "Asset management based on thorough review and analysis ",
    phcotent3_2: "from investment target selection to management",
    phcotent4_1:
      "Securing the stability and reasonable profitability of assets under management ",
    phcotent4_2:
      "with the highest priority by minimizing risk factors caused by market changes",
    phtitle1: "Investor-oriented asset management",
    phtitle2: "Competitive assets from the investment stage",
    phtitle3: "Systematic management based on thorough analysis",
    phtitle4: "Strict and systematic risk management / compliance monitoring ",

    statusType1: "Funds",
    statusType2: "Other Projects",

    information: "Information",
    companyHistory: "History",
  },
};

export const nls = (key) => {
  const l = window.navigator.language.split("-")[0];
  if (l === "ko") {
    return lang["ko"][key];
  }
  return lang["en"][key] || lang["ko"][key];
};
export const nlsLang = (key, l) => {
  if (l === "ko") {
    return lang["ko"][key];
  }
  return lang["en"][key] || lang["ko"][key];
};

export const getLangType = () => {
  return window.navigator.language.split("-")[0];
};
