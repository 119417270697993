import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Column } from "assets/jss/common-styled";
import styles from "assets/jss/views/greeting.js";
import classNames from "classnames";
import { firestore, storage } from "components/Firebase/Firebase";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import styled from "styled-components";
import { dateFormat } from "utils/funcs";

const useStyles = makeStyles(styles);
/**
 * {
 *  id
 *  title
 *  fundType
 *  dateField
 *  date
 *  type
 *  field1Name
 *  field1Content
 *  field2Name
 *  field2Content
 *  field3Name
 *  field3Content
 *  field4Name
 *  field4Content
 *  field5Name
 *  field5Content
 *  img
 * }
 */
function StatusUpload(props) {
  const classes = useStyles();
  const { history } = React.useContext(Context);
  const { ...rest } = props;
  const uselocation = useLocation();
  const [items, setItems] = useState([]);
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    id: -1,
    title: "",
    fundType: "",
    dateField: "",
    date: "",
    field1Name: "",
    field1Content: "",
    field2Name: "",
    field2Content: "",
    field3Name: "",
    field3Content: "",
    field4Name: "",
    field4Content: "",
    field5Name: "",
    field5Content: "",
    img: "",
  });

  useEffect(() => {
    if (uselocation?.state) {
      const state = uselocation.state;
      setData((prev) => ({ ...prev, ...state }));
      return;
    }
    const unsubscribe = firestore
      .collection("status")
      .orderBy("id", "desc")
      .limit(1)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setItems((prev) => [...prev, doc.data()]);
        });
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (items.length > 0)
      setData((prev) => ({ ...prev, id: items[0]?.id + 1 }));
  }, [items]);

  function upload() {
    firestore
      .collection("status")
      .doc(data.id.toString())
      .set({ ...data, date: dateFormat(data.date) }, { merge: true })
      .then(() => {
        console.log("Document successfully written!");
        history.push("/status");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    if (file) {
      const storageRef = storage.ref();
      storageRef
        .child(`status/${file.name}`)
        .put(file)
        .on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  function handleData(event, key) {
    const value = event.target.value;
    setData((prev) => ({ ...prev, [key]: value }));
  }
  function handleFile(event) {
    const newFile = event.target.files[0];
    setFile(newFile);
    setData((prev) => ({ ...prev, img: newFile?.name }));
  }

  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1="운용현황" />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Main>
          <div className="form-box">
            <Grid>
              <Hd>제목</Hd>
              <Item>
                <TextField
                  label="제목"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "title")}
                  value={data?.title || ""}
                />
              </Item>
              <Hd>펀드유형</Hd>
              <Item>
                <TextField
                  label="펀드 유형"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "fundType")}
                  value={data?.fundType}
                />
              </Item>
              <Hd>
                <TextField
                  label="날짜항목명"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "dateField")}
                  value={data?.dateField}
                />
              </Hd>
              <Item>
                <TextField
                  label="날짜"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "date")}
                  value={data?.date}
                  placeholder="YYYY.MM.DD"
                />
              </Item>
              <Hd>
                <TextField
                  label="항목1 명"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field1Name")}
                  value={data?.field1Name}
                />
              </Hd>
              <Item>
                <TextField
                  label="항목1 내용"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field1Content")}
                  value={data?.field1Content}
                />
              </Item>
              <Hd>
                <TextField
                  label="항목2 명"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field2Name")}
                  value={data?.field2Name}
                />
              </Hd>
              <Item>
                <TextField
                  label="항목2 내용"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field2Content")}
                  value={data?.field2Content}
                />
              </Item>
              <Hd>
                <TextField
                  label="항목3 명"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field3Name")}
                  value={data?.field3Name}
                />
              </Hd>
              <Item>
                <TextField
                  label="항목3 내용"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field3Content")}
                  value={data?.field3Content}
                />
              </Item>
              <Hd>
                <TextField
                  label="항목4 명"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field4Name")}
                  value={data?.field4Name}
                />
              </Hd>
              <Item>
                <TextField
                  label="항목4 내용"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleData(e, "field4Content")}
                  value={data?.field4Content}
                />
              </Item>
            </Grid>
            <div>현재 이미지: {data?.img}</div>
            <input type="file" name="file" onChange={handleFile.bind(this)} />
            <Button onClick={upload} variant="contained">
              업로드
            </Button>
          </div>
        </Main>
      </div>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 0 0;
  width: 75rem;
  margin: 3rem 0;
  .form-box {
    ${Column};
    width: 100%;
    height: 50rem;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Grid = styled.div`
  display: inline-grid;
  width: 100%;
  padding: 2rem;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: repeat(8, auto);
  align-content: space-around;
  gap: 1rem 1rem;
`;
const Hd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 3rem;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
`;

export default withRouter(StatusUpload);
