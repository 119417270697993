import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { Column } from "assets/jss/common-styled";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import TimelineContentDetail from "./TimelineContentDetail";

TimeLine.propTypes = {
  items: PropTypes.any,
};

export default function TimeLine({ items }) {
  const data = {
    "2022": items.filter((x) => x.year === "2022"),
    "2021": items.filter((x) => x.year === "2021"),
    "2020": items.filter((x) => x.year === "2020"),
  };

  if (!items?.length) return null;
  return (
    <Wrapper>
      <AlternativeTimeline align="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <StyledTimelineDot />
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContentDetail
            align="left"
            year="2022"
            items={data["2022"]}
          />
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <StyledTimelineDot />
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContentDetail
            align="right"
            year="2021"
            items={data["2021"]}
          />
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <StyledTimelineDot />
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContentDetail
            align="left"
            year="2020"
            items={data["2020"]}
          />
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
      </AlternativeTimeline>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${Column};
  max-width: 75rem;
  width: 100%;
  background-color: white;
`;

const AlternativeTimeline = styled(Timeline)`
  width: 100%;
  padding: 0;
  margin: 0;
`;
const StyledTimelineDot = styled(TimelineDot)`
  border: 1px solid grey;
  background-color: white;
  margin: 0;
  padding: 0;
  padding: 2px;
  box-shadow: 0px 0px 0px;
`;
const StyledTimelineConnector = styled(TimelineConnector)`
  width: 0;
  border-left: 1px dotted grey;
`;
