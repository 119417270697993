import { Button, TextField } from "@material-ui/core";
import { Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

const initValue = {
  order: "",
  header_ko: "",
  header_en: "",
  value_ko: "",
  value_en: "",
};

export default function InformationAdmin() {
  const [isAdmin, setAdmin] = useState(false);
  const [items, setItems] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [newItem, setNewItem] = useState({ ...initValue });

  useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    getItems();
  }, [isAdmin]);

  function getItems() {
    setItems([]);
    firestore
      .collection("company")
      .orderBy("order")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setItems((prev) => [...prev, { id: doc.id, ...doc.data() }]);
        });
      });
  }

  function handleAddButton() {
    setShowAdd(true);
  }

  function handleNewItem(type, e) {
    const value = e.target.value;
    setNewItem((prev) => ({ ...prev, [type]: value }));
  }

  function addNewItem() {
    firestore
      .collection("company")
      .add({
        order: newItem.order,
        header: {
          ko: newItem.header_ko,
          en: newItem.header_en,
        },
        value: {
          ko: newItem.value_ko,
          en: newItem.value_en,
        },
      })
      .then(() => {
        setShowAdd(false);
        setNewItem({ ...initValue });
        window.location.reload();
      });
  }

  function removeItem(id) {
    firestore
      .collection("company")
      .doc(id)
      .delete()
      .then(() => {
        window.location.reload();
      });
  }

  if (!isAdmin) return null;
  return (
    <Wrapper>
      <div>순서: 두자리 숫자 필수</div>
      <Table>
        <Td>순서</Td>
        <Td>헤더(국문)</Td>
        <Td>헤더(영문)</Td>
        <Td>내용(국문)</Td>
        <Td>내용(영문)</Td>
        <Td>삭제</Td>
        {items.map((item) => {
          return (
            <Fragment key={item?.id}>
              <Td>{item?.order}</Td>
              <Td>{item?.header?.ko}</Td>
              <Td>{item?.header?.en}</Td>
              <Td>{item?.value?.ko}</Td>
              <Td>{item?.value?.en}</Td>
              <Td>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => removeItem(item?.id)}
                >
                  삭제
                </Button>
              </Td>
            </Fragment>
          );
        })}
        {showAdd && (
          <>
            <Td>
              <TextField
                label="순서"
                variant="outlined"
                fullWidth
                onChange={(e) => handleNewItem("order", e)}
                value={newItem.order}
              />
            </Td>
            <Td>
              <TextField
                label="헤더(국문)"
                variant="outlined"
                fullWidth
                onChange={(e) => handleNewItem("header_ko", e)}
                value={newItem.header_ko}
              />
            </Td>
            <Td>
              <TextField
                label="헤더(영문)"
                variant="outlined"
                fullWidth
                onChange={(e) => handleNewItem("header_en", e)}
                value={newItem.header_en}
              />
            </Td>
            <Td>
              <TextField
                label="내용(국문)"
                variant="outlined"
                fullWidth
                multiline
                onChange={(e) => handleNewItem("value_ko", e)}
                value={newItem.value_ko}
              />
            </Td>
            <Td>
              <TextField
                label="내용(영문)"
                variant="outlined"
                fullWidth
                onChange={(e) => handleNewItem("value_en", e)}
                value={newItem.value_en}
              />
            </Td>
            <Td>
              <Button variant="contained" onClick={() => {}}>
                임시
              </Button>
            </Td>
          </>
        )}
      </Table>
      {!showAdd && (
        <Button variant="contained" onClick={handleAddButton}>
          추가
        </Button>
      )}
      {showAdd && (
        <Button variant="contained" onClick={addNewItem}>
          확인
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${Column};
  width: 100%;
  height: 100%;
  padding: 2rem;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 5rem 10rem 10rem 1fr 1fr 5rem;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-bottom: 1rem;
`;

const Td = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid black;
  font-size: 0.875rem;
  padding: 2rem 0;
`;
