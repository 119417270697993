import { breakPoints } from "assets/jss/common-styled";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { nls } from "utils/lang";

MemberTable.propTypes = {
  name: PropTypes.string,
  subname: PropTypes.string,
  education: PropTypes.string,
  history: PropTypes.string,
  certificate: PropTypes.string,
};
export default function MemberTable({
  name,
  subname,
  education,
  history,
  certificate,
}) {
  return (
    <Wrapper>
      <NameCard>
        {name}
        <div className="sub-name">{subname}</div>
      </NameCard>
      <Grid>
        <div className="header">{nls("education")}</div>
        <div className="item">{education}</div>
        <div className="header">{nls("history")}</div>
        <div className="item">{history}</div>
        <div className="header">{nls("certificate")}</div>
        <div className="item">{certificate}</div>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 56.25rem;
  /* min-height: 9.875rem; */
  border: 1px solid rgb(175, 175, 175);
  background-color: rgb(240, 240, 240);
`;

const NameCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 9.1875rem;
  max-width: 9.1875rem;
  font-size: 1.125rem;
  font-weight: 800;
  border-right: 1px solid rgb(175, 175, 175);
  background-color: white;
  text-align: center;
  .sub-name {
    font-size: 0.75rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;
  }
  ${breakPoints.lessThan("md")`
      display: none;
    `};
`;

const Grid = styled.div`
  display: inline-grid;
  min-width: 5rem;
  /* height: fit-content; */
  padding: 1rem;
  grid-template-columns: 5.5rem 1fr;
  grid-template-rows: repeat(3, auto);
  align-content: space-around;
  ${breakPoints.lessThan("md")`
      grid-template-columns: 4.1rem 1fr;
    `};
  > .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    border-right: 1px solid rgb(72, 72, 72);
    font-size: 0.875rem;
    font-weight: bold;
    ${breakPoints.lessThan("md")`
      font-size: 0.8rem;
    `};
  }
  > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-wrap;
    word-break: break-all;
    max-width: 100%;
    padding: 0.25rem 0 0.25rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    ${breakPoints.lessThan("md")`
      font-size: 0.8rem;
    `};
  }
`;
