import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";
import { getImage } from "utils/image";

export default function Risk(props) {
  const { nls, getLangType } = React.useContext(Context);
  const { ...rest } = props;
  const level2 = [
    { name: nls("philosophy"), value: "/philosophy" },
    { name: nls("risk"), value: "/risk" },
    { name: nls("compliance"), value: "/compliance" },
  ];
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1={nls("process")} path2={nls("risk")} level2={level2} />
      <Main>
        <div className="container">
          <img src={getImage("Risk", false, getLangType())} alt="risk" />
        </div>
        {getLangType() === "ko" ? (
          <div className="mobile-container">
            <img src={getImage("Risk", true, "ko1")} alt="riskm1" />
            <img src={getImage("Risk", true, "ko2")} alt="riskm2" />
          </div>
        ) : (
          <div className="mobile-container">
            <img src={getImage("Risk", true, "en")} alt="riskme" />
          </div>
        )}
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    ${breakPoints.lessThan("md")`display: none`};
    > img {
      width: 100%;
      margin: 4rem 0;
    }
  }
  .mobile-container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    padding: 0 1rem;
    display: none;
    ${breakPoints.lessThan("md")`display: flex`};
    > img {
      width: 100%;
      margin: 4rem 0;
    }
  }
`;

// const Top = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
// `;
// const Text1 = styled.div`
//   font-size: 1.5625rem;
//   margin-bottom: 1rem;
// `;
// const Text2 = styled.div`
//   display: flex;
//   color: rgb(128, 28, 46);
//   font-weight: 800;
//   font-size: 1.875rem;
//   > div {
//     width: 2.875rem;
//     height: 2.875rem;
//     border-radius: 1.4375rem;
//     background-color: rgb(128, 28, 46);
//     color: white;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-right: 1.5rem;
//     justify-content: center;
//   }
// `;
