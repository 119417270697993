import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";
import { getImage } from "utils/image";

export default function Organization(props) {
  const { nls, getLangType } = React.useContext(Context);
  const { ...rest } = props;

  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("company")}
        path2={nls("organization")}
        category1="company"
      />
      <Main>
        <div className="container">
          <img
            className="default"
            src={getImage("Organization", false, getLangType())}
            alt="organization"
          />
          <img
            className="mobile"
            src={getImage("Organization", true, getLangType())}
            alt="organization"
          />
        </div>
      </Main>
      <Footer />
    </div>
  );
}
const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    ${breakPoints.lessThan("md")`
      padding: 0 1rem;
    `};
    > img {
      width: 100%;
    }
    .default {
      ${breakPoints.lessThan("md")`
        display: none;
      `}
    }
    > .mobile {
      display: none;
      ${breakPoints.lessThan("md")`
        display: block;
      `}
    }
  }
`;
