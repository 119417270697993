import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import LangMenu from "./LangMenu";
import Menu from "./Menu";

const MenuButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;
export default function HeaderLinks(props) {
  const { nls } = React.useContext(Context);
  const { headerType, pathname } = props;
  const menuList = [
    {
      title: nls("company"),
      value: "/information",
      info: [
        { name: nls("information"), value: "/information" },
        { name: nls("companyHistory"), value: "/history" },
        { name: nls("ceo"), value: "/greeting" },
        { name: nls("people"), value: "/members" },
        { name: nls("organization"), value: "/organization" },
        { name: nls("location"), value: "/map" },
      ],
    },
    {
      title: nls("process"),
      value: "/philosophy",
      info: [
        { name: nls("philosophy"), value: "/philosophy" },
        { name: nls("risk"), value: "/risk" },
        { name: nls("compliance"), value: "/compliance" },
      ],
    },
    {
      title: nls("assets"),
      value: "/status",
    },
    {
      title: nls("notice"),
      value: "/notice",
      info: [
        { name: nls("disclosure"), value: "/notice" },
        { name: nls("press"), value: "/media" },
      ],
    },
  ];
  return (
    <MenuButtons>
      <Menu
        title={menuList[0].title}
        value={menuList[0].value}
        subMenu={menuList[0].info}
        selected={["/greeting", "/members", "/organization", "/map"].includes(
          pathname
        )}
        color={headerType === "default" ? "white" : "black"}
      />
      <Menu
        title={menuList[1].title}
        value={menuList[1].value}
        subMenu={menuList[1].info}
        selected={["/philosophy", "/risk", "/compliance"].includes(pathname)}
        color={headerType === "default" ? "white" : "black"}
      />
      <Menu
        title={menuList[2].title}
        value={menuList[2].value}
        selected={pathname === "/status"}
        color={headerType === "default" ? "white" : "black"}
      />
      <Menu
        title={menuList[3].title}
        value={menuList[3].value}
        subMenu={menuList[3].info}
        selected={["/notice", "/media"].includes(pathname)}
        color={headerType === "default" ? "white" : "black"}
      />
      <LangMenu color={headerType === "default" ? "white" : "black"} />
    </MenuButtons>
  );
}
HeaderLinks.propTypes = {
  headerType: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};
