import ComplianceWeben from "assets/imgs/compliance--en.png";
import ComplianceMobileen from "assets/imgs/compliance--mobile--en.png";
import ComplianceMobileko from "assets/imgs/compliance--mobile.png";
import ComplianceWebko from "assets/imgs/compliance.png";
import OrganizationWeben from "assets/imgs/organization--en.png";
import OrganizationMobileen from "assets/imgs/organization--mobile--en.png";
import OrganizationMobileko from "assets/imgs/organization--mobile.png";
import OrganizationWebko from "assets/imgs/organization.png";
import RiskWeben from "assets/imgs/risk--en.png";
import RiskMobileko1 from "assets/imgs/risk--mobile--1.png";
import RiskMobileko2 from "assets/imgs/risk--mobile--2.png";
import RiskMobileen from "assets/imgs/risk--mobile--en.png";
import RiskWebko from "assets/imgs/risk.png";

const images = {
  ComplianceMobileko,
  ComplianceWebko,
  ComplianceMobileen,
  ComplianceWeben,
  OrganizationMobileko,
  OrganizationWebko,
  OrganizationMobileen,
  OrganizationWeben,
  RiskWebko,
  RiskWeben,
  RiskMobileen,
  RiskMobileko1,
  RiskMobileko2,
};
export const getImage = (title, isMobile, lang) => {
  return images[title + (isMobile ? "Mobile" : "Web") + lang];
};
