import roundLogo from "assets/images/logo--round--min.png";
import roundLogoM from "assets/images/logo--round-mobile.png";
import { breakPoints, Column } from "assets/jss/common-styled";
import CardPh from "components/Card/CardPh";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Philosophy(props) {
  const { nls } = React.useContext(Context);
  const { ...rest } = props;
  const level2 = [
    { name: nls("philosophy"), value: "/philosophy" },
    { name: nls("risk"), value: "/risk" },
    { name: nls("compliance"), value: "/compliance" },
  ];
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("process")}
        path2={nls("philosophy")}
        level2={level2}
      />
      <Main>
        <div className="container">
          <img src={roundLogo} alt="roundlogo" />
          <CardBox>
            <CardPh
              title={nls("phtitle1")}
              type="left-top"
              content={nls("phcotent1_1") + " " + nls("phcotent1_2")}
            />
            <CardPh
              title={nls("phtitle2")}
              type="right-top"
              content={nls("phcotent2_1") + " " + nls("phcotent2_2")}
            />
          </CardBox>
          <CardBox>
            <CardPh
              title={nls("phtitle3")}
              type="left-bottom"
              content={nls("phcotent3_1") + " " + nls("phcotent3_2")}
            />
            <CardPh
              title={nls("phtitle4")}
              type="right-bottom"
              content={nls("phcotent4_1") + " " + nls("phcotent4_2")}
            />
          </CardBox>
        </div>
        <div className="mobile-container">
          <img src={roundLogoM} alt="riskLogo" />
          <div className="line" />
          <MobileCard>
            <div className="title">{nls("phtitle1")}</div>
            <div className="text">
              <p>{nls("phcotent1_1")}</p>
              <p>{nls("phcotent1_2")}</p>
            </div>
          </MobileCard>
          <div className="line" />
          <MobileCard>
            <div className="title">{nls("phtitle2")}</div>
            <div className="text">
              <p>{nls("phcotent2_1")}</p>
              <p>{nls("phcotent2_2")}</p>
            </div>
          </MobileCard>
          <div className="line" />
          <MobileCard>
            <div className="title">{nls("phtitle3")}</div>
            <div className="text">
              <p>{nls("phcotent3_1")}</p>
              <p>{nls("phcotent3_2")}</p>
            </div>
          </MobileCard>
          <div className="line" />
          <MobileCard>
            <div className="title">{nls("phtitle4")}</div>
            <div className="text">
              <p>{nls("phcotent4_1")}</p>
              <p>{nls("phcotent4_2")}</p>
            </div>
          </MobileCard>
        </div>
      </Main>
      <Footer />
    </div>
  );
}
const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  background-color: white;
  overflow: hidden;
  .container {
    ${Column};
    justify-content: space-around;
    width: 100%;
    max-width: 75rem;
    min-height: 30rem;
    ${breakPoints.lessThan("md")`display: none`};
    > img {
      position: absolute;
      width: 19.125rem;
      height: 19.125rem;
      ${breakPoints.lessThan("md")`display: none`};
    }
  }
  .mobile-container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    padding: 0 1rem;
    display: none;
    ${breakPoints.lessThan("md")`display: flex`};
    > img {
      width: 9.75rem;
      height: 9.75rem;
    }
    > .line {
      height: 2rem;
      border-left: 1px dotted black;
    }
  }
`;
const CardBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  ${breakPoints.lessThan("md")`justify-content: space-around`};
`;

const MobileCard = styled.div`
  ${Column};
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  height: 10rem;
  box-shadow: 0rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
  > .title {
    width: 100%;
    font-weight: 800;
  }
  > .text {
    ${Column};
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
    background-color: rgb(240, 240, 240);
    height: 70%;
    > p {
      margin: 0;
    }
  }
`;
