import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAaPQQNz3jvpipSzansXzFbjuFkOKAuePY",
  authDomain: "mars-10829.firebaseapp.com",
  projectId: "mars-10829",
  storageBucket: "mars-10829.appspot.com",
  messagingSenderId: "496220286114",
  appId: "1:496220286114:web:49f47609cd45588a633bfb",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const storage = firebase.storage();

export { firebase, firestore, storage };
