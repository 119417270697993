import bg from "assets/images_min/home-section1-bg-min.png";
import { breakPoints, Row } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { getMonth, getYear } from "utils/funcs";
import CountCard from "./CountCard";
export default function Section1() {
  const [value, setValue] = useState({
    "fund-consignment": 0,
    loan: 0,
  });

  function getValue(docName) {
    firestore
      .collection("home")
      .doc(docName)
      .get()
      .then((doc) => {
        const { number } = doc.data();
        setValue((prev) => ({ ...prev, [docName]: +number }));
      });
  }
  useEffect(() => {
    getValue("fund-consignment");
    getValue("loan");
  }, []);

  const date = `${getYear()}년 ${getMonth()}월 현재`;
  return (
    <Wrapper>
      <CountCard
        title={"펀드수탁고"}
        number={value["fund-consignment"]}
        unit="억원"
        date={date}
      />
      <CountCard
        title={"대출 중개 · 주선"}
        number={value.loan}
        unit="억원"
        date={date}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${Row};
  justify-content: center;
  width: 100%;
  height: 34.375rem;
  position: relative;
  background-image: url(${bg});
  background-size: cover;
  ${breakPoints.lessThan("sm")`
    flex-direction: column;
    justify-content: space-around;
    padding: 5rem 0;
  `};
`;
