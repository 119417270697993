import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { breakPoints, Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import Context from "Context";
import React from "react";
import styled from "styled-components";

const MiniStatus = () => {
  const { nls } = React.useContext(Context);
  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState(0);
  const selectedStatus = items[selected];
  React.useEffect(() => {
    const temp = [];
    firestore
      .collection("status")
      .orderBy("id", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setItems(temp);
      });
  }, []);
  const nextStatus = () => {
    setSelected((prev) => {
      return prev >= items.length - 1 ? prev : prev + 1;
    });
  };
  const prevStatus = () => {
    setSelected((prev) => {
      return prev <= 0 ? 0 : prev - 1;
    });
  };
  function checkField(num) {
    const name = `field${num}Name`;
    const content = `field${num}Content`;
    return Boolean(selectedStatus?.[name] && selectedStatus?.[content]);
  }
  function getFieldName(num) {
    const name = `field${num}Name`;
    return selectedStatus?.[name];
  }
  function getFieldContent(num) {
    const content = `field${num}Content`;
    return selectedStatus?.[content];
  }
  const showFundType =
    items[selected]?.fundType && items[selected]?.fundType !== "기타";
  const dateName = items[selected]?.dateField || "설정일";
  return (
    <MiniStatusContainer>
      <Top>
        <span>{nls("assets")}</span>
        <ArrowWrapper>
          <div className="box">
            <ChevronLeftIcon onClick={prevStatus} />
          </div>
          <div className="box">
            <ChevronRightIcon onClick={nextStatus} />
          </div>
        </ArrowWrapper>
      </Top>
      <Content>
        {items[selected] !== undefined && (
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/mars-10829.appspot.com/o/status%2F${items[selected]?.img}?alt=media&token=2a82e72e-a2f1-4400-baf7-49f1058cd93c`}
            alt="img"
          />
        )}
        <div>
          <div className="title">{items[selected]?.title}</div>
          <Detail>
            {showFundType && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">펀드유형</div>
                <div className="line-content">{items[selected]?.fundType}</div>
              </div>
            )}
            {items[selected]?.location && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">소재지</div>
                <div className="line-content">{items[selected]?.location}</div>
              </div>
            )}
            {items[selected]?.content && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">사업내용</div>
                <div className="line-content">{items[selected]?.content}</div>
              </div>
            )}
            {items[selected]?.target && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">투자대상</div>
                <div className="line-content">{items[selected]?.target}</div>
              </div>
            )}
            {items[selected]?.size && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">건물규모</div>
                <div className="line-content">{items[selected]?.size}</div>
              </div>
            )}
            {checkField(1) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(1)}</div>
                <div className="line-content">{getFieldContent(1)}</div>
              </div>
            )}
            {checkField(2) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(2)}</div>
                <div className="line-content">{getFieldContent(2)}</div>
              </div>
            )}
            {checkField(3) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(3)}</div>
                <div className="line-content">{getFieldContent(3)}</div>
              </div>
            )}
            {checkField(4) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(4)}</div>
                <div className="line-content">{getFieldContent(4)}</div>
              </div>
            )}
            {items[selected]?.date && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{dateName}</div>
                <div className="line-content">{items[selected]?.date}</div>
              </div>
            )}
          </Detail>
        </div>
      </Content>
    </MiniStatusContainer>
  );
};

const MiniStatusContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 36rem;
  height: 24rem;
  min-height: 24rem;
  max-height: 24rem;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  ${breakPoints.lessThan("md")`
    padding: 0 1rem;
  `};
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  > span {
    font-size: 1.875rem;
    font-weight: 800;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 3rem;
    ${breakPoints.lessThan("md")`
      font-size: 1.5rem;
      font-weight: 800;
      text-align: center;
      width: 100%;
      ${Column};
    `};
  }
  ${breakPoints.lessThan("md")`
    margin-bottom: 2.125rem;
  `}
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  > img {
    width: 50%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 1rem;
    width: 50%;
    background-color: rgb(159, 35, 38);
    height: 100%;
    max-height: 100%;
    > .title {
      font-size: 1.25rem;
      font-weight: 600;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  > .box {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Detail = styled.div`
  max-width: 100%;
  width: 100%;
  height: 70%;
  max-height: 70%;
  overflow: hidden;
  > .line {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0.9375rem;
    > .line-title {
      width: 35%;
      min-width: 6rem;
      color: rgb(255, 178, 193);
      ${breakPoints.lessThan("sm")`
        display: none;
      `};
    }
    > .line-content {
      width: 65%;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${breakPoints.lessThan("sm")`
        width: 100%;
      `};
    }
  }
`;

export default MiniStatus;
