import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { breakPoints, Column, Row } from "assets/jss/common-styled";
import { firestore, storage } from "components/Firebase/Firebase";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { dateFormat } from "utils/funcs";

const NoticeDetail = (props) => {
  const { item, goList, isAdmin } = props;

  const [modifyMode, setModifyMode] = React.useState(false);
  const [modifyItem, setModifyItem] = React.useState({});
  const [file, setFile] = React.useState(null);

  const [url, setUrl] = React.useState("");

  const upload = () => {
    if (modifyItem?.id) {
      firestore
        .collection("notice")
        .doc(modifyItem.id.toString())
        .set(
          { ...modifyItem, date: dateFormat(modifyItem.date) },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully written!");
          setModifyMode(false);
          goList();
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      if (file) {
        const storageRef = storage.ref();
        storageRef
          .child(`notice/${file.name}`)
          .put(file)
          .on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  };
  const remove = () => {
    firestore
      .collection("notice")
      .doc(modifyItem.id.toString())
      .delete()
      .then(() => {
        goList();
      });
  };
  const download = () => {
    const link = document.createElement("a");
    link.download = true;
    link.href = url;
    link.target = "_blank";
    link.type = "application/octet-stream";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  function getUrl() {
    storage
      .ref(`notice/${item.file}`)
      .getDownloadURL()
      .then(function (u) {
        setUrl(u);
      })
      .catch(function (error) {
        // Handle any errors
        console.log(error);
      });
  }
  function handlerModify() {
    setModifyMode(true);
  }
  function handlerRemove() {
    remove();
  }
  function handlerSave() {
    upload();
  }
  function handlerTitle(event) {
    const value = event.target.value;
    setModifyItem((prev) => ({ ...prev, title: value }));
  }
  function handlerDate(event) {
    const value = event.target.value;
    setModifyItem((prev) => ({ ...prev, date: value }));
  }
  function handlerContent(event) {
    const value = event.target.value;
    setModifyItem((prev) => ({ ...prev, content: value }));
  }
  React.useEffect(() => {
    if (item) {
      getUrl();
    }
  }, [item]);
  React.useEffect(() => {
    if (item) {
      setModifyItem({ ...item });
    }
  }, [item]);

  const handleFile = (event) => {
    const temp = event.target.files[0];
    if (item.file === temp.name) {
      alert("기존 파일과 다른 file 이름으로 업로드해주세요.");
    } else {
      setFile(temp);
      setModifyItem((prev) => ({ ...prev, file: temp.name }));
    }
  };
  return (
    <NoticeDetailContainer>
      {modifyMode ? (
        <Title>
          <TextBox
            fullWidth
            onChange={handlerTitle}
            value={modifyItem?.title}
          />
          <TextBox
            mask={"9999.99.99"}
            placeholder="YYYY.MM.DD"
            onChange={handlerDate}
            value={modifyItem?.date}
          />
        </Title>
      ) : (
        <Title>
          <div className="title">{item?.title}</div>
          <div className="date">{item?.date}</div>
        </Title>
      )}
      {!modifyMode ? (
        <Content>
          {item?.content?.split("\n").map((line, idx) => {
            return (
              <span key={idx}>
                {line}
                <br />
              </span>
            );
          })}
        </Content>
      ) : (
        <Content>
          <TextBox
            fullWidth
            multiline
            onChange={handlerContent}
            value={modifyItem?.content}
          />
        </Content>
      )}
      <DownloadSection>
        <div className="title">첨부파일</div>
        <div className="download" onClick={download}>
          {item?.file}
        </div>
        {modifyMode && (
          <input type="file" name="file" onChange={handleFile.bind(this)} />
        )}
      </DownloadSection>
      {isAdmin && (
        <AdminSection>
          {modifyMode ? (
            <Button onClick={handlerSave} variant="outlined">
              저장
            </Button>
          ) : (
            <>
              <Button onClick={handlerModify} variant="outlined">
                수정
              </Button>
              <Button onClick={handlerRemove} variant="outlined">
                삭제
              </Button>
            </>
          )}
        </AdminSection>
      )}
      <ListButton onClick={goList}>목록보기</ListButton>
    </NoticeDetailContainer>
  );
};

const NoticeDetailContainer = styled.div`
  ${Column};
  width: 100%;
`;
const Title = styled.div`
  ${Row};
  width: 100%;
  height: 4rem;
  border-bottom: 2px solid rgb(72, 72, 72);
  padding: 0 1rem;
  justify-content: space-between;
  > .title {
    font-size: 1.125rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > .date {
    width: 6rem;
    min-width: 76px;
    font-size: 1.125rem;
    font-weight: 400;
    ${breakPoints.lessThan("md")`
      font-size: 0.8rem;
    `};
  }
`;
const TextBox = styled(TextField)`
  margin: 0 0.5rem;
`;
const Content = styled.div`
  ${Column};
  align-items: flex-start;
  width: 100%;
  min-height: 14rem;
  padding: 2rem 1rem 1rem;
  > span {
    white-space: pre-wrap;
  }
`;
const DownloadSection = styled.div`
  ${Row};
  width: 100%;
  height: 3.375rem;
  padding: 0 1rem;
  border-top: 1px solid rgb(72, 72, 72);
  border-bottom: 2px solid rgb(72, 72, 72);
  font-size: 0.9rem;
  > .title {
    margin-right: 2rem;
    min-width: 3.625rem;
  }
  > .download {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > input {
    margin: 0 1rem;
  }
`;
const ListButton = styled.div`
  width: 10.8125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(72, 72, 72);
  color: white;
  cursor: pointer;
  margin-top: 3rem;
`;
const AdminSection = styled.div`
  ${Row};
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0;
`;

NoticeDetail.propTypes = {
  item: PropTypes.any,
  goList: PropTypes.any,
  isAdmin: PropTypes.bool,
};
export default NoticeDetail;
