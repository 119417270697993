import Button from "@material-ui/core/Button";
import { breakPoints, Row } from "assets/jss/common-styled";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export default function StatusDetail({
  selectedStatus,
  nextStatus,
  goList,
  isAdmin,
  handlerModify,
  remove,
}) {
  const [test, setTest] = React.useState();
  const dateName = selectedStatus?.dateField || "설정일";

  function checkField(num) {
    const name = `field${num}Name`;
    const content = `field${num}Content`;
    return Boolean(selectedStatus?.[name] && selectedStatus?.[content]);
  }
  function getFieldName(num) {
    const name = `field${num}Name`;
    return selectedStatus?.[name];
  }
  function getFieldContent(num) {
    const content = `field${num}Content`;
    return selectedStatus?.[content];
  }
  return (
    <StatusDetailContainer>
      <StatusDetailMain>
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/mars-10829.appspot.com/o/status%2F${selectedStatus?.img}?alt=media&token=2a82e72e-a2f1-4400-baf7-49f1058cd93c`}
          alt="img"
        />
        <div className="right">
          <div className="title">{selectedStatus?.title}</div>
          <div className="content">
            {selectedStatus?.content && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">사업내용</div>
                <div className="line-content">{selectedStatus?.content}</div>
              </div>
            )}
            {selectedStatus?.location && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">소재지</div>
                <div className="line-content">{selectedStatus?.location}</div>
              </div>
            )}
            {selectedStatus?.fundType && selectedStatus?.fundType !== "기타" && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">펀드유형</div>
                <div className="line-content">{selectedStatus?.fundType}</div>
              </div>
            )}
            {selectedStatus?.target && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">투자대상</div>
                <div className="line-content">{selectedStatus?.target}</div>
              </div>
            )}
            {selectedStatus?.date && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{dateName}</div>
                <div className="line-content">{selectedStatus?.date}</div>
              </div>
            )}
            {checkField(1) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(1)}</div>
                <div className="line-content">{getFieldContent(1)}</div>
              </div>
            )}
            {checkField(2) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(2)}</div>
                <div className="line-content">{getFieldContent(2)}</div>
              </div>
            )}
            {checkField(3) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(3)}</div>
                <div className="line-content">{getFieldContent(3)}</div>
              </div>
            )}
            {checkField(4) && (
              <div className="line">
                <div className="dot" />
                <div className="line-title">{getFieldName(4)}</div>
                <div className="line-content">{getFieldContent(4)}</div>
              </div>
            )}
          </div>
          <WarningText>
            * 상기 이미지는 이해를 돕기위한 사진이며, 실제와는 다를 수 있습니다.
          </WarningText>
        </div>
      </StatusDetailMain>
      {nextStatus && (
        <NextInfo>
          <span>다음상품</span>
          <div>{nextStatus.title}</div>
        </NextInfo>
      )}
      {isAdmin && (
        <AdminSection>
          <Button
            onClick={() => handlerModify(selectedStatus.id)}
            variant="outlined"
          >
            수정
          </Button>
          <Button onClick={() => remove(selectedStatus.id)} variant="outlined">
            삭제
          </Button>
        </AdminSection>
      )}
      <ListButton onClick={goList}>목록보기</ListButton>
    </StatusDetailContainer>
  );
}

const StatusDetailContainer = styled.div`
  width: 100%;
  max-width: 75rem;
  min-height: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${breakPoints.lessThan("md")`padding: 0 1rem;`}
`;
const NextInfo = styled.div`
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-left: 2rem;
  margin-top: 2rem;
  font-weight: 600;
  > span {
    width: 10rem;
    color: rgb(128, 28, 46);
  }
  > div {
    color: rgb(72, 72, 72);
  }
`;
const ListButton = styled.div`
  width: 10.8125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(159, 35, 38);
  margin-top: 2rem;
  color: white;
  cursor: pointer;
`;
const StatusDetailMain = styled.div`
  ${Row};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 28.125rem;
  > img {
    width: 32.5rem;
    height: 26.25rem;
    overflow: hidden;
    object-fit: cover;
  }
  > .right {
    width: 39.75rem;
    display: flex;
    flex-direction: column;
    ${breakPoints.lessThan("md")`margin: 2rem 0;`}
    > .title {
      font-weight: 800;
      font-size: 1.875rem;
      color: black;
      min-height: 3rem;
      border-bottom: 1px solid black;
      line-height: 2.5rem;
      padding-bottom: 0.2rem;
    }
    > .content {
      width: 100%;
      min-height: 11.375rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);
      margin-top: 1rem;
      padding: 2rem;
      > .line {
        display: flex;
        height: 2.5rem;
        font-size: 1.125rem;
        align-items: center;
        > .dot {
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 0.125rem;
          background-color: black;
          margin-right: 1rem;
        }
        > .line-title {
          font-weight: bold;
          width: 4.5rem;
          margin-right: 2rem;
        }
        > .line-content {
        }
      }
    }
  }
`;
const WarningText = styled.div`
  ${Row};
  justify-content: flex-end;
  width: 100%;
  height: 2.5rem;
  font-weight: 400;
  font-size: 0.85rem;
  color: grey;
`;
const AdminSection = styled.div`
  ${Row};
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0;
`;
StatusDetail.propTypes = {
  selectedStatus: PropTypes.any,
  nextStatus: PropTypes.any,
  goList: PropTypes.any,
  isAdmin: PropTypes.any,
  handlerModify: PropTypes.any,
  remove: PropTypes.any,
};
