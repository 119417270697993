import { Button } from "@material-ui/core";
import { Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import MainLayout from "componentsV2/layout/MainLayout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { nls } from "utils/lang";
import TimeLine from "./components/TimeLine";

export default function History() {
  const [items, setItems] = useState([]);
  const [isAdmin, setAdmin] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
    getItems();
  }, []);

  function getItems() {
    setItems([]);
    firestore
      .collection("history")
      .orderBy("month", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setItems((prev) => [...prev, { id: doc.id, ...doc.data() }]);
        });
      });
  }

  function goAdmin() {
    history.push("history_admin");
  }
  return (
    <MainLayout
      path1={nls("company")}
      path2={nls("companyHistory")}
      category1="company"
    >
      <Wrapper>
        <Box>Company History</Box>
        <TimeLine items={items} />
      </Wrapper>
      {isAdmin && (
        <Button variant="contained" onClick={goAdmin}>
          수정
        </Button>
      )}
    </MainLayout>
  );
}

const Wrapper = styled.div`
  ${Column};
  max-width: 75rem;
  width: 100%;
  padding: 3.25rem 1.125rem 0 1.125rem;
  background-color: white;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #313131;
  border-radius: 5px;
  width: 20.875rem;
  height: 4.25rem;
  color: white;
  font-weight: bold;
  font-size: 1.875rem;
`;
