import TimelineContent from "@material-ui/lab/TimelineContent";
import { breakPoints } from "assets/jss/common-styled";
import Context from "Context";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import styled from "styled-components";

TimelineContentDetail.propTypes = {
  align: PropTypes.string,
  items: PropTypes.any,
  year: PropTypes.string,
};

export default function TimelineContentDetail({ align, year, items }) {
  const { getLangType } = useContext(Context);
  const month = getLangType() === "ko" ? "월" : "";

  return (
    <TimelineContent>
      <Container>
        <Year $align={align}>{year}</Year>
        {align === "left" && (
          <LeftTable>
            {items?.map((item, idx) => {
              return (
                <Fragment key={item?.year + idx}>
                  <Td>{item?.text?.[getLangType()] || item?.text?.ko}</Td>
                  <Th>{item?.month + month}</Th>
                </Fragment>
              );
            })}
          </LeftTable>
        )}
        {align === "right" && (
          <RightTable>
            {items?.map((item, idx) => {
              return (
                <Fragment key={item?.year + idx}>
                  <ThR>{item?.month + month}</ThR>
                  <Td>{item?.text?.[getLangType()] || item?.text?.ko}</Td>
                </Fragment>
              );
            })}
          </RightTable>
        )}
      </Container>
    </TimelineContent>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Year = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.7rem;
  font-weight: bold;
  color: #9f233a;
  margin-bottom: 1.5rem;
  justify-content: ${(props) =>
    props.$align === "left" ? "flex-end" : "flex-start"};
`;

const LeftTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.25rem;
  width: 100%;
  row-gap: 1.25rem;
  padding-bottom: 7rem;
  ${breakPoints.lessThan("sm")`
    grid-template-columns: 1fr 2rem;
    padding-bottom: 5rem;
  `};
`;
const RightTable = styled.div`
  display: grid;
  grid-template-columns: 3.25rem 1fr;
  width: 100%;
  row-gap: 1.25rem;
  padding-bottom: 7rem;
  ${breakPoints.lessThan("sm")`
    grid-template-columns: 2rem 1fr;
    padding-bottom: 5rem;
  `};
`;

const Th = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  ${breakPoints.lessThan("sm")`
    font-size: 0.875rem;
  `};
`;
const ThR = styled.div`
  display: flex;
  font-weight: bold;
  ${breakPoints.lessThan("sm")`
    font-size: 0.875rem;
  `};
`;
const Td = styled.div`
  white-space: pre-wrap;
  ${breakPoints.lessThan("sm")`
    font-size: 0.875rem;
  `};
`;
