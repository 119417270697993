import { Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

MainLayout.propTypes = {
  path1: PropTypes.any,
  path2: PropTypes.any,
  level2: PropTypes.any,
  category1: PropTypes.string,
  children: PropTypes.any,
};

export default function MainLayout({
  path1,
  path2,
  level2 = null,
  category1,
  children,
}) {
  return (
    <>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={path1}
        path2={path2}
        level2={level2}
        category1={category1}
      />
      <Container>{children}</Container>
      <Footer />
    </>
  );
}
const Container = styled.div`
  ${Column};
  width: 100%;
  padding-top: 1.5rem;
  background-color: white;
  position: relative;
`;
