import { Button } from "@material-ui/core";
import { breakPoints, Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import MainLayout from "componentsV2/layout/MainLayout";
import Context from "Context";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { nls } from "utils/lang";

export default function Information() {
  const { getLangType } = useContext(Context);
  const [items, setItems] = useState([]);
  const [isAdmin, setAdmin] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
    getItems();
  }, []);

  function getItems() {
    setItems([]);
    firestore
      .collection("company")
      .orderBy("order")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setItems((prev) => [...prev, { id: doc.id, ...doc.data() }]);
        });
      });
  }

  function goAdmin() {
    history.push("information_admin");
  }
  return (
    <MainLayout
      path1={nls("company")}
      path2={nls("information")}
      category1="company"
    >
      <Wrapper>
        <Table>
          {items?.map((item) => {
            const h = item?.header?.[getLangType()] || item?.header?.ko;
            const d = item?.value?.[getLangType()] || item?.value?.ko;

            return (
              <Fragment key={item?.id}>
                <Th>{h}</Th>
                <Td>{d}</Td>
              </Fragment>
            );
          })}
        </Table>
      </Wrapper>
      {isAdmin && (
        <Button variant="contained" onClick={goAdmin}>
          수정
        </Button>
      )}
    </MainLayout>
  );
}

const Wrapper = styled.div`
  ${Column};
  max-width: 67.5rem;
  width: 100%;
  padding: 1.25rem 1.125rem;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin: 3.5rem 0 4.5rem 0;
  background-color: white;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 15.75rem 1fr;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  ${breakPoints.lessThan("sm")`
    grid-template-columns: 1fr;
  `};
`;

const Th = styled.div`
  ${Column};
  justify-content: center;
  width: 100%;
  height: 3.875rem;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #801c2e;
  ${breakPoints.lessThan("sm")`
    border-right: 0px;
    height: 2.5rem;
  `};
`;
const Td = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  width: 100%;
  height: 3.875rem;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 600;
  white-space: pre-wrap;
  ${breakPoints.lessThan("sm")`
    max-width: 100%;
    font-size: 0.8rem;
    padding: 0 0.5rem;
    overflow: hidden;
  `};
`;
