import { ClickAwayListener, MenuItem, MenuList } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

const Menu = (props) => {
  const { title, subMenu, value, color, selected, history } = props;
  const haveSubMenu = subMenu !== undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(anchorEl || event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRoute = (link) => {
    setAnchorEl(null);
    history.push(link);
  };
  const handleValue = (link) => {
    setAnchorEl(null);
    history.push(link);
  };
  const useStyles = makeStyles(() =>
    createStyles({
      default: {
        backgroundColor: "transparent",
        borderBottom: "0.5rem solid rgb(0, 0, 0, 0)",
        color: selected ? "rgb(159 ,35, 58)" : color,
        minHeight: "100%",
        fontSize: "1.125rem",
        width: "9.5rem",
        fontWeight: 600,
        borderRadius: 0,
        margin: 0,
        boxShadow: "0px 0px 0px",
      },
      selected: {
        backgroundColor: "transparent",
        borderBottom: "0.5rem solid rgb(237, 80, 109)",
        color: selected ? "rgb(159 ,35, 58)" : color,
        height: "100%",
        fontSize: "1.125rem",
        width: "9.5rem",
        fontWeight: 600,
        borderRadius: 0,
        margin: 0,
        boxShadow: "0px 0px 0px",
      },
      paper: {
        borderBottom: "0.5rem solid rgb(0, 0, 0, 0)",
        color: "white",
        height: "100%",
        fontSize: "1.125rem",
        minWidth: "9.5rem",
        fontWeight: 600,
        borderRadius: 0,
        backgroundColor: "rgb(159, 35, 58)",
      },
      popper: {
        zIndex: 999,
      },
    })
  );
  const classes = useStyles();
  return (
    <div>
      <Button
        className={selected ? classes.selected : classes.default}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={() => handleValue(value)}
      >
        {title}
      </Button>
      {haveSubMenu && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          className={classes.popper}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                {subMenu?.map((m) => {
                  return (
                    <MenuItem
                      onClick={() => handleRoute(m.value)}
                      key={m.value}
                    >
                      {m.name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      )}
    </div>
  );
};
Menu.propTypes = {
  title: PropTypes.string.isRequired,
  subMenu: PropTypes.any,
  selected: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(["white", "black"]).isRequired,
  value: PropTypes.string,
  history: PropTypes.any,
};
export default withRouter(Menu);
