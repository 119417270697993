import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { breakPoints } from "assets/jss/common-styled";
import PageNumberButtons from "components/CustomButtons/PageNumberButtons";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import NoticeDetail from "./NoticeDetail";

const NoticeList = (props) => {
  const location = useLocation();
  const { items, isAdmin, setItems } = props;
  const [type, setType] = React.useState("all");
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (location.state) {
      setSelected(location.state.selectedId);
    }
  }, [location]);

  const handleClick = (id) => {
    setSelected(id);
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const handlePageChange = (num) => {
    setPage(num);
  };

  const filterType = (t) => {
    if (type === "" || type === "all") return true;
    return t === type;
  };

  const filteredList = items.filter((item) => filterType(item?.type));
  const pageCount = Math.ceil(filteredList?.length / 10);
  const pagedFilteredList = filteredList.slice((page - 1) * 10, page * 10);

  if (selected !== null) {
    return (
      <NoticeDetail
        item={items.filter((item) => item.id === selected)[0]}
        goList={() => setSelected(null)}
        isAdmin={isAdmin}
        setItems={setItems}
      />
    );
  }
  return (
    <NoticeListContainer>
      <Top>
        <StyledSelect
          value={type}
          onChange={handleChange}
          placeholder="유형선택"
        >
          <MenuItem value={"all"}>전체</MenuItem>
          <MenuItem value={"management"}>경영공시</MenuItem>
          <MenuItem value={"fund"}>펀드공시</MenuItem>
        </StyledSelect>
      </Top>
      <NoticeTable>
        {pagedFilteredList.map((item) => {
          return (
            <NoticeItem onClick={() => handleClick(item?.id)} key={item?.id}>
              <div className="small num">{item?.id + 1}</div>
              <div className="small none">
                <NoticeType
                  color={
                    item?.type === "fund"
                      ? "rgb(186, 69, 91)"
                      : "rgb(89, 183, 86)"
                  }
                >
                  {item?.type === "fund" ? "펀드공시" : "경영공시"}
                </NoticeType>
              </div>
              <div className="big">{item?.title}</div>
              <div className="small">{item?.date}</div>
            </NoticeItem>
          );
        })}
      </NoticeTable>
      <PageNumberButtons length={pageCount} onChangePage={handlePageChange} />
    </NoticeListContainer>
  );
};

const NoticeListContainer = styled.div`
  width: 100%;
`;
const Top = styled.div`
  width: 100%;
  height: 4rem;
  justify-content: space-between;
`;
const StyledSelect = styled(Select)`
  width: 9.375rem;
  height: 2rem;
  padding: 0.5rem;
  border: 1px solid rgb(175, 175, 175);
  option {
    width: 19.375rem;
  }
`;
const NoticeTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid rgb(175, 175, 175);
  border-top: 2px solid rgb(175, 175, 175);
  border-bottom: 2px solid rgb(175, 175, 175);
`;
const NoticeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.75rem;
  border-top: 1px solid rgb(175, 175, 175);
  cursor: pointer;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: 600;
    height: 100%;
  }
  > .small {
    width: 12rem;
    &.num {
      ${breakPoints.lessThan("md")`width: 3rem`};
    }
    &.none {
      ${breakPoints.lessThan("md")`display: none`};
    }
  }
  > .big {
    justify-content: flex-start;
    width: 100%;
    padding-left: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${breakPoints.lessThan("md")`padding: 0; `};
  }
`;
const NoticeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 1.875rem;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
`;

NoticeList.propTypes = {
  items: PropTypes.array,
  isAdmin: PropTypes.bool,
  setItems: PropTypes.any,
};
export default NoticeList;
