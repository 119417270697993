import { ClickAwayListener, MenuItem, MenuList } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Row } from "assets/jss/common-styled";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const SubMenu = (props) => {
  const { title, subMenu, history } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(anchorEl || event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRoute = (link) => {
    setAnchorEl(null);
    history.push(link);
  };

  const useStyles = makeStyles(() =>
    createStyles({
      paper: {
        borderBottom: "1px solid rgb(118, 24, 41)",
        borderLeft: "1px solid rgb(118, 24, 41)",
        borderRight: "1px solid rgb(118, 24, 41)",
        color: "rgb(49, 49, 49)",
        height: "100%",
        fontSize: "1.125rem",
        width: "13.75rem",
        fontWeight: 600,
        borderRadius: 0,
        backgroundColor: "white",
      },
      popper: {
        zIndex: 999,
      },
    })
  );
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SubMenuContainer>
        <StyledButton onClick={handleOpen}>
          <span>{title}</span>
          {open ? <ArrowUp /> : <ArrowDown />}
        </StyledButton>
        <Popper open={open} anchorEl={anchorEl} className={classes.popper}>
          <Paper className={classes.paper}>
            <MenuList id="menu-list-grow">
              {subMenu?.map((m) => {
                return (
                  <MenuItem onClick={() => handleRoute(m.value)} key={m.value}>
                    {m.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Popper>
      </SubMenuContainer>
    </ClickAwayListener>
  );
};
SubMenu.propTypes = {
  title: PropTypes.string.isRequired,
  subMenu: PropTypes.any,
  history: PropTypes.any,
};
const SubMenuContainer = styled.div`
  ${Row};
  justify-content: center;
  width: 13.75rem;
  height: 100%;
`;
const StyledButton = styled.div`
  ${Row};
  justify-content: center;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  border-right: 1px solid rgb(118, 24, 41);
  padding: 0 0.875rem;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  margin-left: 1rem;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid white;
`;
const ArrowDown = styled.div`
  width: 0;
  height: 0;
  margin-left: 1rem;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.4rem solid white;
`;
export default withRouter(SubMenu);
