const componentsStyle = {
  // container,
  main: {
    background: "white",
    position: "relative",
  },
  mainRaised: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  mainTitle: {
    color: "black",
    fontSize: "2.375rem",
    fontWeight: "600",
  },
};

export default componentsStyle;
