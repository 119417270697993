/*global kakao*/

import React from "react";

const MapContainer = () => {
  React.useEffect(() => {
    kakao.maps.load(() => {
      let container = document.getElementById("Mymap");
      let options = {
        center: new kakao.maps.LatLng(37.5023088, 127.044437),
        level: 4,
      };

      const map = new window.kakao.maps.Map(container, options);
      let markerPosition = new kakao.maps.LatLng(37.5023088, 127.044437);

      // 마커를 생성
      let marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      // 마커를 지도 위에 표시
      marker.setMap(map);
    });
    // };
  }, []);
  return (
    <div
      id="Mymap"
      style={{
        width: "75rem",
        height: "33rem",
      }}
    />
  );
};

export default MapContainer;
