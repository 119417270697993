import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import SubMenu from "./SubMenu";

export default function SubHeader({ path1, path2, level2, category1 = null }) {
  const { nls } = React.useContext(Context);

  const level1 = [
    { name: nls("company"), value: "/greeting" },
    { name: nls("process"), value: "/philosophy" },
    { name: nls("assets"), value: "/status" },
    { name: nls("notice"), value: "/notice" },
  ];
  const subMenuObject = {
    company: [
      { name: nls("information"), value: "/information" },
      { name: nls("companyHistory"), value: "/history" },
      { name: nls("ceo"), value: "/greeting" },
      { name: nls("people"), value: "/members" },
      { name: nls("organization"), value: "/organization" },
      { name: nls("location"), value: "/map" },
    ],
  };

  const subMenu = subMenuObject[category1] ?? level2;
  return (
    <Container>
      <div className="sub-header">
        <SubMenu key="menu1" title={path1} subMenu={level1} />
        {path2 && <SubMenu key="menu2" title={path2} subMenu={subMenu} />}
      </div>
    </Container>
  );
}
const Container = styled("div")`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  .sub-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: -30px;
    width: 100%;
    max-width: 75rem;
    height: 3.125rem;
    background-color: rgb(159, 35, 58);
    z-index: 1;
  }
  .path {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13.75rem;
    height: 100%;
    color: white;
    // font-family: SpoqaHanSansNeo;
    font-weight: 600;
    border-right: 1px solid rgb(118, 24, 41);
    font-size: 1.25rem;
  }
`;

SubHeader.propTypes = {
  path1: PropTypes.string,
  path2: PropTypes.string,
  level2: PropTypes.any,
  category1: PropTypes.string, // company
};
