import Button from "@material-ui/core/Button";
import { breakPoints, Column, Row } from "assets/jss/common-styled";
import CardSt from "components/Card/CardSt";
import { firestore } from "components/Firebase/Firebase";
import StatusDetail from "components/Status/StatusDetail";
import useMatchParams from "componentsV2/common/useMatchParams";
import Context from "Context";
import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

const StatusList = () => {
  const { nls } = React.useContext(Context);
  const { history } = React.useContext(Context);
  const [isAdmin, setAdmin] = React.useState(false);
  const [items, setItems] = React.useState([]);

  const { statusId } = useMatchParams();

  useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
    const temp = [];
    const unsubscribe = firestore
      .collection("status")
      .orderBy("id", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setItems(temp);
      });
    return () => unsubscribe();
  }, []);
  const handleClickCard = (id) => {
    history.push(`/status/${id}`);
  };
  function handler() {
    history.push("/status_upload");
  }
  function handlerModify() {
    history.push({
      pathname: "/status_upload",
      state: { ...items.find(({ id }) => id === statusId) },
    });
  }
  function remove(id) {
    firestore
      .collection("status")
      .doc(id.toString())
      .delete()
      .then(() => {
        goList();
        window.location.reload();
      });
  }
  function goList() {
    history.push("/status");
  }

  const fundItems = items.filter(({ fundType }) => fundType !== "기타");
  const devItems = items.filter(({ fundType }) => fundType === "기타");

  if (!items.length) return null;
  return (
    <Wrapper>
      <Switch>
        <Route exact path="/status">
          <TypeTitle>{nls("statusType1")}</TypeTitle>
          <StatusListContainer>
            {fundItems.length > 0 &&
              fundItems.map((item) => {
                return (
                  <CardSt
                    key={item.id}
                    id={item.id}
                    handler={handleClickCard}
                    title={item.title}
                    date={item.date}
                    img={item.img}
                  />
                );
              })}
            {fundItems.length % 3 === 2 && <Dummy />}
          </StatusListContainer>
          {devItems.length > 0 && (
            <>
              <TypeTitle>{nls("statusType2")}</TypeTitle>
              <StatusListContainer>
                {devItems.map((item) => {
                  return (
                    <CardSt
                      key={item.id}
                      id={item.id}
                      handler={handleClickCard}
                      title={item.title}
                      date={item.date}
                      img={item.img}
                    />
                  );
                })}
                {devItems.length % 3 === 2 && <Dummy />}
              </StatusListContainer>
            </>
          )}
        </Route>
        <Route path={`/status/:${statusId}`}>
          <StatusDetail
            selectedStatus={items.find(({ id }) => +id === +statusId)}
            nextStatus={
              items[items.findIndex(({ id }) => +id === +statusId) - 1]
            }
            goList={goList}
            isAdmin={isAdmin}
            handlerModify={handlerModify}
            remove={remove}
          />
        </Route>
      </Switch>
      {isAdmin && (
        <StatusBottom>
          <Button onClick={handler} variant="outlined">
            작성하기
          </Button>
        </StatusBottom>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  ${Column};
  width: 100%;
`;
const StatusListContainer = styled.div`
  ${Row};
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  ${breakPoints.lessThan("md")`justify-content: space-around`};
  margin-bottom: 2rem;
`;
const TypeTitle = styled.div`
  ${Row};
  width: 100%;
  font-size: 1.5625rem;
  font-weight: 800;
  padding: 0 0 1.5rem 0;
`;
const StatusBottom = styled.div`
  ${Row};
  width: 75rem;
  height: 3rem;
  margin-top: 4rem;
  padding-right: 2rem;
  justify-content: flex-end;
`;
const Dummy = styled.div`
  width: 22.8125rem;
  height: 26.875rem;
`;
export default withRouter(StatusList);
