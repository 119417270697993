import { css } from "styled-components";
import { generateMedia } from "styled-media-query";

const breakPoints = generateMedia({
  md: "960px",
  sm: "600px",
  xsm: "300px",
});

const Row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Column = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HoverTransition = css`
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-1rem);
  }
`;
export { Row, Column, HoverTransition, breakPoints };
