import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import MemberTable from "components/Members/MemberTable";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Members(props) {
  const { nls } = React.useContext(Context);
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("company")}
        path2={nls("people")}
        category1="company"
      />
      <Main>
        <div className="container">
          <div className="title">
            <span>{nls("people")}</span>
            <div className="line" />
          </div>
          <div className="content-box">
            <div className="mobile-name">{nls("m1")}</div>
            <MemberTable
              name={nls("m1")}
              education={nls("school1")}
              history={nls("hist1")}
              certificate={nls("certi1")}
            />
            <div className="mobile-name">{nls("m2")}</div>
            <MemberTable
              name={nls("m2")}
              education={nls("school2")}
              history={nls("hist2")}
              certificate={nls("certi2")}
            />
            <div className="mobile-name">{nls("m3")}</div>
            <MemberTable
              name={nls("m3_1")}
              subname={nls("m3_2")}
              education={nls("school3")}
              history={nls("hist3")}
              certificate={nls("certi3")}
            />
            <div className="mobile-name">{nls("m4")}</div>
            <MemberTable
              name={nls("m4")}
              education={nls("school4")}
              history={nls("hist4")}
              certificate={nls("certi4")}
            />
            <div className="mobile-name">{nls("m5")}</div>
            <MemberTable
              name={nls("m5")}
              education={nls("school5")}
              history={nls("hist5")}
              certificate={nls("certi5")}
            />
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.25rem;
    ${breakPoints.lessThan("md")`padding: 0 0.5rem;`};
    > span {
      font-size: 1.875rem;
      font-weight: bold;
      color: black;
      min-width: 9rem;
      white-space: nowrap;
    }
    .line {
      max-width: 65rem;
      width: 100%;
      height: 0;
      border-bottom: 1px solid rgb(128, 28, 46);
    }
  }
  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 10rem;
    width: 100%;
    min-height: 68rem;
    ${breakPoints.lessThan("md")`padding: 0.5rem`};
    .mobile-name {
      display: none;
      font-weight: 600;
      font-size: 1.125rem;
      margin-top: 1rem;
      ${breakPoints.lessThan("md")`
        display: block;
      `};
    }
  }
`;
