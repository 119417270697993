import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
export default function CardSt(props) {
  const { id, title, img, date, handler } = props;

  return (
    <CardContainer onClick={() => handler(id)}>
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/mars-10829.appspot.com/o/status%2F${img}?alt=media&token=2a82e72e-a2f1-4400-baf7-49f1058cd93c`}
        alt="img"
      />
      <div>
        <div className="title">{title}</div>
        <div className="date">{date}</div>
      </div>
    </CardContainer>
  );
}
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22.8125rem;
  height: 26.875rem;
  border: 1px solid rgb(112, 112, 112);
  /* margin-right: 4rem; */
  margin-bottom: 2.8125rem;
  cursor: pointer;
  > img {
    width: 100%;
    height: 19.625rem;
    min-height: 19.625rem;
  }
  > div {
    width: 100%;
    height: 100%;
    border-top: 1px solid rgb(112, 112, 112);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.375rem 1.125rem;
    > .title {
      width: 100%;
      font-size: 1rem;
      color: rgb(49, 49, 49);
    }
    > .date {
      color: rgb(160, 160, 160);
    }
  }
`;
CardSt.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  date: PropTypes.string,
  handler: PropTypes.any,
  id: PropTypes.number,
};
