/* eslint-disable */
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import whiteLogo from "assets/images/logo--white.png";
import defaultLogo from "assets/images/logo2.png";
import { Row } from "assets/jss/common-styled";
import styles from "assets/jss/components/headerStyle.js";
import classNames from "classnames";
import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import HeaderLinks from "./HeaderLinks";
import LangMenu from "./LangMenu";

const useStyles = makeStyles(styles);

function Header(props) {
  const {
    color,
    rightLinks,
    leftLinks,
    fixed,
    absolute,
    location,
    isContent,
    history,
  } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headerType, setHeaderType] = React.useState("default");
  const [pathname, setPathname] = React.useState("");
  const { nls } = React.useContext(Context);

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;

    if (windowsScrollTop > changeColorOnScroll.height) {
      setHeaderType("white");
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      setHeaderType("default");
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  React.useEffect(() => {
    if (isContent) {
      setHeaderType("white");
      return;
    }
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  }, [isContent]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (location) {
      setPathname(location.pathname);
    }
  }, [location]);
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const handleHistory = (link) => {
    setMobileOpen(false);
    history?.push(link);
  };
  return (
    <>
      <Hidden xsDown implementation="css">
        <AppBar className={appBarClasses}>
          <Toolbar className={classes.container}>
            {headerType === "default" ? (
              <img
                className={classes.logo}
                src={whiteLogo}
                alt="logo"
                onClick={() => history?.push("/")}
              />
            ) : (
              <img
                className={classes.logo}
                src={defaultLogo}
                alt="logo"
                onClick={() => history?.push("/")}
              />
            )}
            <HeaderLinks headerType={headerType} pathname={pathname} />
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smUp implementation="css">
        <MobileHeader>
          <img
            src={defaultLogo}
            alt="logoMobile"
            onClick={() => history?.push("/")}
          />
          <div className="right-box">
            <LangMenu color="black" />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </div>
        </MobileHeader>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <List className={classes.list}>
              <LItem onClick={() => handleHistory("/information")}>
                <RightMenu color="transparent">{nls("information")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/history")}>
                <RightMenu color="transparent">
                  {nls("companyHistory")}
                </RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/greeting")}>
                <RightMenu color="transparent">{nls("ceo")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/members")}>
                <RightMenu color="transparent">{nls("people")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/organization")}>
                <RightMenu color="transparent">{nls("organization")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/map")}>
                <RightMenu color="transparent">{nls("location")}</RightMenu>
              </LItem>

              <LItem onClick={() => handleHistory("/philosophy")}>
                <RightMenu color="transparent">{nls("philosophy")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/risk")}>
                <RightMenu color="transparent">{nls("risk")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/compliance")}>
                <RightMenu color="transparent">{nls("compliance")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/status")}>
                <RightMenu color="transparent">{nls("assets")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/notice")}>
                <RightMenu color="transparent">{nls("disclosure")}</RightMenu>
              </LItem>
              <LItem onClick={() => handleHistory("/media")}>
                <RightMenu color="transparent">{nls("press")}</RightMenu>
              </LItem>
            </List>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
}

const MobileHeader = styled.div`
  ${Row};
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 4rem;
  background-color: white;
  position: fixed;
  z-index: 1100;
  flex-flow: "row nowrap";
  box-shadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)";
  padding: 0 1rem;
  > img {
    width: 8rem;
  }
  > .right-box {
    ${Row};
  }
`;

const RightMenu = styled.div`
  ${Row};
  padding: 0.2rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
`;

const LItem = styled(ListItem)`
  cursor: pointer;
  &:hover {
    background-color: rgb(240, 240, 240);
  }
`;

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
  location: PropTypes.any,
  isContent: PropTypes.bool,
  history: PropTypes.any,
};

export default withRouter(Header);
