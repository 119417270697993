import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import MapContainer from "components/MapContainer/MapContainer.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Map(props) {
  const { ...rest } = props;
  const { nls } = React.useContext(Context);
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("company")}
        path2={nls("location")}
        category1="company"
      />
      <Main>
        <div className="container">
          <MapContainer />
          <div className="address">{nls("addr")}</div>
        </div>
      </Main>
      <Footer />
    </div>
  );
}
const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    > img {
      width: 100%;
    }
    .address {
      width: 100%;
      border-bottom: 1px solid black;
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 1rem 0;
    }
  }
`;
