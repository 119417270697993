import { breakPoints, Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import React from "react";
import styled from "styled-components";
import { getImage } from "utils/image";

export default function Risk(props) {
  const { ...rest } = props;
  const { nls, getLangType } = React.useContext(Context);

  const level2 = [
    { name: nls("philosophy"), value: "/philosophy" },
    { name: nls("risk"), value: "/risk" },
    { name: nls("compliance"), value: "/compliance" },
  ];
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader
        path1={nls("process")}
        path2={nls("compliance")}
        level2={level2}
      />
      <Main>
        <div className="container">
          <img
            src={getImage("Compliance", false, getLangType())}
            alt="compliance"
          />
        </div>
        <div className="mobile-container">
          <img
            src={getImage("Compliance", true, getLangType())}
            alt="compliance"
          />
        </div>
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  min-height: 40rem;
  background-color: white;
  overflow: hidden;
  ${breakPoints.lessThan("md")`padding: 6.25rem 1rem`};
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    ${breakPoints.lessThan("md")`display: none`};
    > img {
      width: 100%;
      margin: 4rem 0;
      ${breakPoints.lessThan("md")`margin: 0`};
    }
  }
  .mobile-container {
    ${Column};
    width: 100%;
    max-width: 75rem;
    display: none;
    ${breakPoints.lessThan("md")`display: flex`};
    > img {
      width: 100%;
      margin: 4rem 0;
    }
  }
`;
