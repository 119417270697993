import { breakPoints, Row } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import MiniNotice from "components/Home/MiniNotice";
import MiniStatus from "components/Home/MiniStatus";
import Parallax from "components/Parallax/Parallax.js";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Section1 from "./components/Section1";

export default function Home(props) {
  const { history, ...rest } = props;
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax />
      <Section1 />
      <Section2>
        <div className="section-container">
          <div className="mini">
            <MiniStatus />
          </div>
          <div className="mini">
            <MiniNotice history={history} />
          </div>
        </div>
      </Section2>
      <Footer />
    </div>
  );
}

const Section2 = styled.div`
  ${Row};
  justify-content: center;
  width: 100%;
  min-height: 34rem;
  position: relative;
  > .section-container {
    ${Row};
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 75rem;
    flex-wrap: wrap;
    > .mini {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36rem;
      height: 34rem;
      ${breakPoints.lessThan("md")`
        width: 100%;
      `};
    }
  }
`;
Home.propTypes = {
  history: PropTypes.any,
};
