import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { breakPoints, Row } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const MediaContainer = (props) => {
  const { history } = React.useContext(Context);

  const { items } = props;
  const [isAdmin, setAdmin] = React.useState(false);
  const [checkedList, setCheckedList] = React.useState([]);

  let unsubscribe;
  const handleCheck = (id) => {
    setCheckedList((prev) => {
      if (prev.includes(id)) return prev.filter((i) => i !== id);
      else return [...prev, id];
    });
  };
  const handleClick = (link) => {
    window.open(link, "_blank");
  };
  const handler = () => {
    history.push("/media_upload");
  };
  const handlerModify = () => {
    if (checkedList.length !== 1) return;
    const item = items.find((e) => e.id === checkedList[0]);
    console.log("itemtiemt", item);
    history.push({
      pathname: "/media_upload",
      state: item,
    });
  };
  const remove = () => {
    checkedList.forEach((item) => {
      unsubscribe = firestore
        .collection("media")
        .doc(item.toString())
        .delete()
        .then(window.location.reload());
    });
  };

  React.useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <MContainer>
      <MediaGrid>
        <span className="th">No</span>
        <span className="th">제목</span>
        <span className="th">출처</span>
        <span className="th">작성일</span>
        {items.map((item) => (
          <>
            <span className="td" onClick={() => handleClick(item.link)}>
              {isAdmin && <Checkbox onChange={() => handleCheck(item.id)} />}
              {item.id}
            </span>
            <span
              className="td flex-start"
              onClick={() => handleClick(item.link)}
            >
              {item.title}
            </span>
            <span className="td right" onClick={() => handleClick(item.link)}>
              {item.source}
            </span>
            <span className="td right" onClick={() => handleClick(item.link)}>
              {item.date}
            </span>
          </>
        ))}
      </MediaGrid>
      {isAdmin && (
        <StatusBottom>
          <Button onClick={handler} variant="outlined">
            작성하기
          </Button>
          {checkedList.length === 1 && (
            <Button onClick={handlerModify} variant="outlined">
              수정
            </Button>
          )}
          {checkedList.length > 0 && (
            <Button onClick={remove} variant="outlined">
              삭제
            </Button>
          )}
        </StatusBottom>
      )}
    </MContainer>
  );
};

const MContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MediaGrid = styled.div`
  display: inline-grid;
  grid-template-columns: 7.5rem 1fr 7.5rem 7.5rem;
  width: 100%;
  border-top: 2px solid rgb(175, 175, 175);
  border-bottom: 2px solid rgb(175, 175, 175);
  ${breakPoints.lessThan("sm")`
      grid-template-columns: 3.5rem 1fr 3.5rem 3.5rem;
    `};
  > span {
    text-align: center;
    line-height: 3.75rem;
    width: 100%;
    height: 3.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > .th {
    border-bottom: 2px solid rgb(175, 175, 175);
    font-size: 0.875rem;
    font-weight: bold;
    ${breakPoints.lessThan("sm")`
      font-size: 0.75rem;
      `};
  }
  > .td {
    font-size: 0.9375rem;
    font-weight: 600;
    border-bottom: 1px solid rgb(175, 175, 175);
    ${breakPoints.lessThan("sm")`
      font-size: 0.8rem;
      padding: 0 0.25rem;
      `};
  }
  > .flex-start {
    text-align: start;
  }
  > .right {
    ${breakPoints.lessThan("sm")`
      text-align: start;
      `};
  }
`;
const StatusBottom = styled.div`
  ${Row};
  width: 75rem;
  height: 3rem;
  margin-top: 4rem;
  padding-right: 2rem;
  justify-content: flex-end;
`;
MediaContainer.propTypes = {
  items: PropTypes.array,
};

export default withRouter(MediaContainer);
