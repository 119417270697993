import { breakPoints, Column, Row } from "assets/jss/common-styled";
import { useScroll } from "componentsV2/common/useScroll";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useCountUp } from "react-countup";
import styled from "styled-components";

CountCard.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  unit: PropTypes.string,
  date: PropTypes.string,
};

export default function CountCard({ title, number, unit, date }) {
  const countUpRef = useRef(null);
  const [countStart, setCountStart] = useState(false);
  const { scrollY } = useScroll();

  const { start, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: number,
    delay: 0,
    duration: 3,
    separator: ",",
  });

  useEffect(() => {
    setCountStart((prev) => prev || scrollY >= 150);
  }, [scrollY]);

  useEffect(() => {
    start();
  }, [countStart]);

  useEffect(() => {
    update(number);
  }, [number]);
  return (
    <Wrapper>
      <Title>{title}</Title>
      <NumberSection>
        <Number ref={countUpRef} />
        <Unit>{unit}</Unit>
      </NumberSection>
      <Date>{date}</Date>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${Column};
  align-items: flex-start;
  justify-content: space-between;
  width: 35.125rem;
  height: 17.375rem;
  border: 1px solid #484848;
  padding: 3.75rem 4.5rem;
  & + & {
    margin-left: 4.75rem;
  }
  ${breakPoints.lessThan("sm")`
    width: 95%;
    height: 10rem;
    padding: 1rem;
    & + & {
      margin: 0;
    }
  `};
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;
const NumberSection = styled.div`
  ${Row};
  align-items: flex-end;
`;

const Number = styled.div`
  font-size: 4.375rem;
  font-weight: bold;
  margin-right: 1rem;
  vertical-align: bottom;
`;
const Unit = styled.div`
  font-size: 1.5625rem;
  font-weight: bold;
  padding-bottom: 0.75rem;
`;
const Date = styled.div`
  font-size: 1.0625rem;
`;
