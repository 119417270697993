import { breakPoints, Column } from "assets/jss/common-styled";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export default function CardPh(props) {
  const { title, content, type } = props;

  return type.includes("left") ? (
    <CardContainerLeft>
      <span>{title}</span>
      <div className={type}>
        <p>{content.split("_")[0]}</p>
        <p>{content.split("_")[1]}</p>
      </div>
    </CardContainerLeft>
  ) : (
    <CardContainerRight>
      <span>{title}</span>
      <div className={type}>
        <p>{content.split("_")[0]}</p>
        <p>{content.split("_")[1]}</p>
      </div>
    </CardContainerRight>
  );
}
const CardContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.875rem 0;
  width: 31rem;
  height: 10rem;
  box-shadow: 4px 4px 16px 2px rgba(3, 3, 3, 0.16);
  border-left: 0.875rem solid rgb(186, 69, 91);
  border-radius: 0 1rem 1rem 0;
  ${breakPoints.lessThan("md")`margin: 1rem 0`};
  > span {
    font-weight: bold;
    width: 90%;
    max-width: 27.625rem;
    font-size: 1.25rem;
  }
  > div {
    ${Column};
    justify-content: center;
    padding: 0 1.25rem;
    width: 90%;
    max-width: 27.625rem;
    height: 5.375rem;
    background-color: rgb(240, 240, 240);
    > p {
      margin: 0;
      max-width: 100%;
      width: 100%;
      font-size: 1.05rem;
    }
  }
  > .left-top {
    border-radius: 0 1rem 0 0;
  }
  > .left-bottom {
    border-radius: 0 0 1rem 0;
  }
`;
const CardContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.875rem 0;
  width: 100%;
  max-width: 31rem;
  height: 10rem;
  box-shadow: 4px 4px 16px 2px rgba(3, 3, 3, 0.16);
  border-right: 0.875rem solid rgb(186, 69, 91);
  border-radius: 1rem 0 0 1rem;
  ${breakPoints.lessThan("md")`margin: 1rem 0`};
  > span {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    max-width: 27.625rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
  > div {
    ${Column};
    align-items: flex-start;
    justify-content: center;
    padding: 0 1.25rem;
    width: 90%;
    max-width: 27.625rem;
    height: 5.375rem;
    background-color: rgb(240, 240, 240);
    > p {
      margin: 0;
      max-width: 100%;
      text-align: right;
      font-size: 1.05rem;
    }
  }
  > .right-top {
    border-radius: 1rem 0 0 0;
  }
  > .right-bottom {
    border-radius: 0 0 0 1rem;
  }
`;
CardPh.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.oneOf([
    "left-top",
    "left-bottom",
    "right-top",
    "right-bottom",
  ]),
};
