import { Column } from "assets/jss/common-styled";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import StatusList from "components/Status/StatusList";
import Context from "Context";
import React from "react";
import styled from "styled-components";

export default function Status(props) {
  const { nls } = React.useContext(Context);
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1={nls("assets")} />
      <Main>
        <div className="container">
          <StatusList />
        </div>
      </Main>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  ${Column};
  position: relative;
  width: 100%;
  padding: 6.25rem 0;
  background-color: white;
  .container {
    ${Column};
    width: 100%;
    max-width: 75rem;
  }
`;
