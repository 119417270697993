import { breakPoints, Row } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import Context from "Context";
import React from "react";
import styled from "styled-components";

const MiniNotice = () => {
  const { nls, history } = React.useContext(Context);
  const [type, setType] = React.useState("notice"); // media || notice;
  const [items, setItems] = React.useState([]);

  const handleType = (t) => {
    setType(t);
  };
  React.useEffect(() => {
    const temp = [];
    firestore
      .collection(type)
      .orderBy("id", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setItems(temp);
      });
  }, [type]);
  const detail = () => {
    return (
      <Detail>
        {type === "media"
          ? items.map((item, idx) => {
              if (idx > 5) return null;
              return (
                <Line
                  key={item.id}
                  onClick={() => window.open(item?.link, "_blank")}
                >
                  <div className="line-left">
                    <div className="dot" />
                    <div className="line-title">
                      {"[" + item.source + "] " + item.title}
                    </div>
                  </div>
                  <div className="line-right">{item.date}</div>
                </Line>
              );
            })
          : items.map((item, idx) => {
              if (idx > 5) return null;
              return (
                <Line
                  key={item.id}
                  onClick={() =>
                    history.push({
                      pathname: "/notice",
                      state: { selectedId: item.id },
                    })
                  }
                >
                  <div className="line-left">
                    <div className="dot" />
                    <div className="line-title">{item.title}</div>
                  </div>
                  <div className="line-right">{item.date}</div>
                </Line>
              );
            })}
      </Detail>
    );
  };

  return (
    <MiniNoticeContainer>
      <Top>
        <div className="buttons">
          <Button
            onClick={() => handleType("notice")}
            isSelected={type === "notice"}
          >
            {nls("disclosure")}
          </Button>
          <Button
            onClick={() => handleType("media")}
            isSelected={type === "media"}
          >
            {nls("press")}
          </Button>
        </div>
      </Top>
      <Content>{detail()}</Content>
      {/* <Content>
        <PrepareBox>준비중입니다.</PrepareBox>
      </Content> */}
    </MiniNoticeContainer>
  );
};

const MiniNoticeContainer = styled.div`
  width: 100%;
  max-width: 36rem;
  height: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakPoints.lessThan("md")`
    padding: 0 1rem;
  `}
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 2px solid rgb(159, 35, 38);
  > .buttons {
    display: flex;
  }
`;
const Button = styled.div`
  font-size: 1.875rem;
  font-weight: 800;
  color: ${(props) => (props.isSelected ? "black" : "grey")};
  cursor: pointer;
  margin: 0 1rem;
  ${breakPoints.lessThan("md")`
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0 0.25rem;
  `};
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const Detail = styled.div`
  width: 100%;
  height: 70%;
  padding: 1.5rem 0;
`;
const Line = styled.div`
  ${Row};
  width: 100%;
  height: 2rem;
  justify-content: space-between;
  font-size: 1.25rem;
  color: black;
  margin: 0.75rem 0;
  cursor: pointer;
  > .line-left {
    display: flex;
    align-items: center;
    height: 100%;
    width: 70%;
    > .dot {
      min-width: 0.5rem;
      height: 0.5rem;
      background-color: rgb(159, 35, 38);
      margin-right: 1.25rem;
      border-radius: 0.25rem;
    }
    > .line-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ${breakPoints.lessThan("xsm")`
      width: 100%;
    `};
  }
  > .line-right {
    color: rgb(116, 116, 116);
    ${breakPoints.lessThan("md")`
      font-size: 0.9rem;
    `};
    ${breakPoints.lessThan("xsm")`
      display: none;
    `};
  }
`;

export default MiniNotice;
