import { Button, TextField } from "@material-ui/core";
import { Column } from "assets/jss/common-styled";
import { firestore } from "components/Firebase/Firebase";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

export default function HomeAdmin() {
  const [isAdmin, setAdmin] = useState(false);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({});

  useEffect(() => {
    setAdmin(localStorage.getItem("admin") === "true");
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    getItems();
  }, [isAdmin]);

  function getItems() {
    setItems([]);
    firestore
      .collection("home")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setItems((prev) => [...prev, { id: doc.id, ...doc.data() }]);
          setNewItem((prev) => ({ ...prev, [doc.id]: doc.data().number }));
        });
      });
  }

  function handleButton() {
    Object.entries(newItem).forEach(([key, item]) => {
      firestore.collection("home").doc(key).set({
        number: item,
      });
    });
  }

  function handleNewItem(type, e) {
    const value = e.target.value;
    setNewItem((prev) => ({ ...prev, [type]: value }));
  }
  if (!isAdmin) return null;
  return (
    <Wrapper>
      <Table>
        {items.map((item) => {
          return (
            <Fragment key={item?.id}>
              <Td>{item?.id}</Td>
              <Td>
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleNewItem(item?.id, e)}
                  value={newItem?.[item?.id]}
                />
              </Td>
            </Fragment>
          );
        })}
      </Table>
      <Button variant="contained" onClick={handleButton}>
        확인
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${Column};
  width: 100%;
  height: 100%;
  padding: 2rem;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 10rem 10rem;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-bottom: 1rem;
`;

const Td = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid black;
  font-size: 0.875rem;
  padding: 2rem 0;
`;
