import "assets/scss/mars.scss?v=1.9.0";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
// var hist = createBrowserHistory();

// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/" component={Home} exact={true} />
//       <Route path="/greeting" component={Greeting} exact={true} />
//       <Route path="/members" component={Members} exact={true} />
//       <Route path="/organization" component={Organization} exact={true} />
//       <Route path="/philosophy" component={Philosophy} exact={true} />
//       <Route path="/map" component={Map} exact={true} />
//       <Route path="/risk" component={Risk} exact={true} />
//       <Route path="/compliance" component={Compliance} exact={true} />
//       <Route path="/status" component={Status} exact={true} />
//       <Route path="/notice" component={Notice} exact={true} />
//       <Route path="/media" component={Media} exact={true} />
//       <Route path="/admin" component={Admin} exact={true} />
//       <Route path="/status_upload" component={StatusUpload} exact={true} />
//       <Route path="/media_upload" component={MediaUpload} exact={true} />
//       <Route path="/notice_upload" component={NoticeUpload} exact={true} />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
