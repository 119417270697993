import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Column } from "assets/jss/common-styled";
import styles from "assets/jss/views/greeting.js";
import classNames from "classnames";
import { firestore } from "components/Firebase/Firebase";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import SubHeader from "components/Header/SubHeader.js";
import Parallax from "components/Parallax/ParallaxContent.js";
import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import styled from "styled-components";
import { dateFormat } from "utils/funcs";

const useStyles = makeStyles(styles);

function MediaUpload(props) {
  const classes = useStyles();
  const { history } = React.useContext(Context);
  const { ...rest } = props;
  const [id, setId] = React.useState(-1);
  const location = useLocation();
  const [title, setTitle] = React.useState("");
  const [link, setLink] = React.useState("");
  const [date, setDate] = React.useState("");
  const [source, setSource] = React.useState("");

  const upload = () => {
    firestore
      .collection("media")
      .doc(id.toString())
      .set({
        id,
        title,
        link,
        source,
        date: dateFormat(date),
      })
      .then(() => {
        console.log("Document successfully written!");
        history.push("/media");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  React.useEffect(() => {
    if (location?.state) {
      setId(location.state.id);
      setTitle(location.state.title);
      setLink(location.state.link);
      setSource(location.state.source);
      setDate(location.state.date);
    } else {
      firestore
        .collection("media")
        .orderBy("id", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            setId(data.id + 1);
          });
        });
    }
  }, []);

  function handlerTitle(event) {
    setTitle(event.target.value);
  }
  function handlerSource(event) {
    setSource(event.target.value);
  }
  function handlerLink(event) {
    setLink(event.target.value);
  }
  function handlerDate(event) {
    setDate(event.target.value);
  }

  return (
    <div>
      <Header
        brand="MARS Asset Management"
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        isContent={true}
        {...rest}
      />
      <Parallax image={require("assets/images/content-min.png")}></Parallax>
      <SubHeader path1="공지사항" />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Main>
          <div className="form-box">
            <TextField
              label="제목"
              variant="outlined"
              fullWidth
              onChange={handlerTitle}
              value={title}
            />
            <TextField
              label="출처"
              variant="outlined"
              fullWidth
              onChange={handlerSource}
              value={source}
            />
            <TextField
              label="작성일"
              variant="outlined"
              fullWidth
              placeholder="YYYY.MM.DD"
              onChange={handlerDate}
              value={date}
            />
            <TextField
              label="링크"
              variant="outlined"
              fullWidth
              onChange={handlerLink}
              value={link}
            />
            <Button onClick={upload} variant="contained">
              업로드
            </Button>
          </div>
        </Main>
      </div>
      <Footer />
    </div>
  );
}

const Main = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 0 0;
  width: 75rem;
  /* height: 34rem; */
  margin: 3rem 0;
  .form-box {
    ${Column};
    width: 100%;
    height: 40rem;
    justify-content: space-between;
    align-items: flex-start;
    /* height: 75rem; */
  }
`;
MediaUpload.propTypes = {
  history: PropTypes.any,
};
export default withRouter(MediaUpload);
