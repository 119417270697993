import { makeStyles } from "@material-ui/core/styles";
import bg1 from "assets/images/home/main--1.png";
import bg2 from "assets/images/home/main--2.png";
import bg3 from "assets/images/home/main--3.png";
import arrow from "assets/images/outline-arrow.png";
import { breakPoints, Column, Row } from "assets/jss/common-styled";
import styles from "assets/jss/components/parallaxStyle.js";
import classNames from "classnames";
import Context from "Context";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const useStyles = makeStyles(styles);

export default function Parallax(props) {
  const { nls } = React.useContext(Context);
  const [pos, setPos] = React.useState(1);

  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const { filter, className, style, small } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [className]: className !== undefined,
  });

  const scrollHandler = () => {
    window.scrollTo({ top: window.innerHeight, left: 0, behavior: "smooth" });
  };
  React.useEffect(() => {
    const tick = setInterval(() => {
      setPos(pos < 3 ? pos + 1 : 1);
    }, 6000);

    return () => clearInterval(tick);
  }, [pos]);

  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        transform: transform,
      }}
    >
      <Slider>
        <ul className={"pos" + pos}>
          <li>
            <div className="content-box">
              <div className="title-box">
                <div className="sub-title">{nls("landing1")}</div>
                <div className="title">MARS Asset Management</div>
              </div>
            </div>
          </li>
          <li>
            <div className="content-box">
              <div className="title-box">
                <div className="sub-title">{nls("landing2")}</div>
                <div className="title">MARS Asset Management</div>
              </div>
            </div>
          </li>
          <li>
            <div className="content-box">
              <div className="title-box">
                <div className="sub-title">{nls("landing3")}</div>
                <div className="title">MARS Asset Management</div>
              </div>
            </div>
          </li>
        </ul>
        <div className="dots">
          <div
            className={pos === 1 ? "dot checked" : "dot"}
            onClick={() => setPos(1)}
          />
          <div
            className={pos === 2 ? "dot checked" : "dot"}
            onClick={() => setPos(2)}
          />
          <div
            className={pos === 3 ? "dot checked" : "dot"}
            onClick={() => setPos(3)}
          />
        </div>
        <div className="arrow" onClick={scrollHandler}>
          <img src={arrow} alt="arrow" />
        </div>
      </Slider>
    </div>
  );
}

const Slider = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .arrow {
    position: absolute;
    left: calc(50% - 24px);
    bottom: 2rem;
    cursor: pointer;
    filter: brightness(70%);
  }
  .dots {
    ${Column};
    position: absolute;
    top: 48%;
    left: calc(50% - 30rem);
    align-items: center;
    height: 3rem;
    justify-content: space-between;
    margin-right: 8rem;
    ${breakPoints.lessThan("md")`
      ${Row};
      justify-content: space-between;
      width: 2rem;
      top: calc(50% + 2.5rem);
      left: calc(50% - 1rem);
    `};
  }
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    background: white;
    font-size: 0;
    transition: 0.5s;
    cursor: pointer;
  }
  .checked {
    background-color: rgb(159, 35, 58);
  }
  .content-box {
    ${Row};
    justify-content: center;
    width: 100%;
    height: 100vh;

    .title-box {
      ${Column};
      justify-content: center;
      color: white;
      .title {
        font-size: 4rem;
        font-weight: 600;
        line-height: 6rem;
        font-family: ConceptBold;
        text-align: center;
        ${breakPoints.lessThan("md")`
          font-size: 1.75rem;
          line-height: 2rem;
          margin-top: 0.25rem;
        `};
      }
      .sub-title {
        text-align: center;
        font-size: 1.875rem;
        line-height: 2rem;
        ${breakPoints.lessThan("md")`
          font-size: 1.5rem;
          line-height: 1.5rem;
        `};
      }
    }
  }
  ul,
  li {
    list-style: none;
    padding: 0;
  }
  ul {
    height: calc(100% * 4);
    transition: 1s;
  }
  li {
    height: calc(100% / 4);
  }
  li:nth-child(1) {
    background-image: url(${bg1});
  }
  li:nth-child(2) {
    background-image: url(${bg2});
  }
  li:nth-child(3) {
    background-image: url(${bg3});
  }

  /* 슬라이드 조작 */
  .pos1 {
    margin-top: 0;
  }
  .pos2 {
    margin-top: -100vh;
  }
  .pos3 {
    margin-top: -200vh;
  }
`;
Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};
